import React from 'react';
import { withRouter } from 'react-router-dom';
import { store } from '../../../index';
import { checkReport } from '../../../actions/ApartmentActions';
import MoreInfoComponent from './MoreInfoComponent';
import { connect } from 'react-redux';
import ApartmentTenantComponent from './ApartmentTenantComponent';

class ApartmentTableRowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState()
    }

    getInitialState = () => {
        return {
            showInfo: false
        };
    }

    toggleShowInfo = () => {
        this.setState({ showInfo: !this.state.showInfo })
    }

    render = function () {
        const self = this;

        var tenants = this.props.apartment.apartmentTenants.map(function (apartmentTenant) {
            return (<React.Fragment key={apartmentTenant.id}>
                <ApartmentTenantComponent apartmentTenant={apartmentTenant} warning={self.props.apartment.apartmentTenants.length > 2 ? "table-warning" : ""} toggleShowInfo={self.toggleShowInfo}></ApartmentTenantComponent>
            </React.Fragment>)
        });
        // var tenants = <tbody><tr></tr></tbody>;
        return (
            <React.Fragment >
                <tr className={"according-toggle clickMobile"} data-toggle='collapse' data-target={"#" + self.props.apartment.number}  >
                    <td onClick={() => self.setState({ showInfo: !self.state.showInfo })} style={{ textAlign: "center" }}>{self.props.apartment.number} </td>
                    <td onClick={() => self.setState({ showInfo: !self.state.showInfo })} >{self.props.apartment.address} {self.props.apartment.postcode} {self.props.apartment.suburb}</td>
                    <table onClick={() => self.setState({ showInfo: !self.state.showInfo })} className="table table-responsive table-striped table-hover">
                        {tenants}{/* all tenants for this apartment */}
                    </table>

                    <td style={{ textAlign: "center" }}>
                        <input type="checkbox" checked={this.props.report.includes(this.props.apartment.id)}
                            onChange={(event) => {
                                if(event.target.checked){
                                    self.props.addToReportList(this.props.apartment.id);
                                } else {
                                    self.props.removeFromReportList(this.props.apartment.id);
                                }
                                // self.setState({ ["report_" + self.props.apartment.id]: event.target.checked });
                                // self.props.apartment["report_" + self.props.apartment.id] = event.target.checked;
                                // store.dispatch(checkReport(self.props.apartment))
                            }} />
                    </td>
                    <td onClick={() => this.setState({ showInfo: !this.state.showInfo })} style={{ textAlign: "center" }}><span className={self.props.apartment.for_sale ? 'bi bi-check-circle' : 'bi bi-circle-fill'}>&nbsp;</span></td>
                </tr>
                {this.state.showInfo ? <MoreInfoComponent apartment={self.props.apartment}></MoreInfoComponent> : <div></div>}
            </React.Fragment >
        );
    }

}
export default withRouter(ApartmentTableRowComponent);