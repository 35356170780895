import React from 'react';
import { withRouter } from 'react-router-dom';
import AuthenticatedComponent from '../../AuthenticatedComponent';
import WaitingListService from '../../../services/WaitingListService';
import PersonService from '../../../services/PersonService';
import EditWaitingListEntryComponent from './EditWaitingListEntryComponent';
import { formatStringDate } from '../../../helpers/dateFormatter';

class WaitingListEntryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            editEntry: false,
            referer: {
                id: null,
                title: null,
                firstname: null,
                middlename: null,
                lastname: null,
                address: null,
                postcode: null,
                suburb: null,
                phone: null,
                work: null,
                email: null,
            }
        };
    }

    componentDidMount = () => {
        const self = this;
        if (!!this.props.waitingListEntry.referer_id && this.props.waitingListEntry.waiting_list_type_id === 2) {
            // this.setState({ referer: PersonService.get(this.props.waitingListEntry.referer_id) });
            PersonService.get(this.props.waitingListEntry.referer_id).then((person) => {
                self.setState({ referer: person });
            })
        }
    }

    updateReferer = (referer) => {
        this.setState({referer: referer})
    }

    redigerClick = () => {
        this.setState({ editEntry: !this.state.editEntry })
    }

    delete = () => {
        if(this.props.isWaitingList){
            WaitingListService.deleteWaitinglistEntry(this.props.waitingListEntry);
        } else {
            WaitingListService.deleteApplication(this.props.waitingListEntry);
        }
        this.props.toggleShowInfo();
    }

    render = () => {
        return (<tr>
            {!this.state.editEntry ?
                <td colSpan="6">
                    <div className="panel panel-default">
                        <div className="panel-body textAlignLeft" >
                            <div className="row">
                                {this.props.waitingListEntry.waiting_list_type_id === 2 &&
                                    <React.Fragment>
                                        <div className="col-md panelRowPadding">
                                            <label>Referere navn</label><br />
                                            {this.state.referer.firstname} {this.state.referer.lastname}
                                        </div>
                                        <div className="col-md panelRowPadding">
                                            <label>Referere Address</label><br />
                                            {this.state.referer.address} {this.state.referer.postcode} {this.state.referer.suburb}
                                        </div>
                                        <div className="col-md panelRowPadding">
                                            <label>Referere Email</label><br />
                                            {this.state.referer.email}
                                        </div>
                                        <div className="col-md panelRowPadding">
                                            <label>Referere Telefon</label><br />
                                            {this.state.referer.phone}
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            <div className="row">
                                <div className="col-md-6 panelRowPadding">
                                    <label>Dato for indskrivelse</label><br />
                                    {formatStringDate(this.props.waitingListEntry.entry_date)}
                                </div>
                                {
                                    this.props.waitingListEntry.note &&
                                    <div className="col-md-6 panelRowPadding">
                                        <label>Noter</label><br />
                                        {this.props.waitingListEntry.note}
                                    </div>
                                }
                                <div className="col-md-6 panelRowPadding">
                                    <label>Betalingsdetaljer</label><br />
                                    Sidste betalingsdato: <b>{formatStringDate(this.props.waitingListEntry.last_payment_date)}</b><br />
                                    Sidste betalingsbeløb : <b>{this.props.waitingListEntry.last_payment_date&&this.props.waitingListEntry.last_payment_amount/100}</b><br />
                                    Sidste betalingsreference: <b>{this.props.waitingListEntry.last_payment_id}</b>
                                </div>

                            </div>
                            <div className="row">
                                <div className=" col-md-offset-6 col-md-3 panelRowPadding">
                                    <button className="btn btn-primary" onClick={this.delete.bind(this)}>Slet</button>
                                </div>
                                <div className="col-md-3 panelRowPadding">
                                    <button className="btn btn-primary" onClick={this.redigerClick}>Rediger</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </td>

                :
                <EditWaitingListEntryComponent updateReferer={this.updateReferer} isWaitingList={this.props.isWaitingList} waitingListEntry={this.props.waitingListEntry} referer={this.state.referer} redigerClick={this.redigerClick} ></EditWaitingListEntryComponent>
            }
        </tr>);
    }
}

export default AuthenticatedComponent(withRouter(WaitingListEntryComponent));