import request from 'reqwest';
import when from 'when';
import { LOGIN_URL } from '../constants/LoginConstants';
import { loginUser, logoutUser } from '../actions/LoginActions';
import { store } from '../index';

class AuthService {

  login(username, password) {
    return this.handleAuth(when(request({
      url: LOGIN_URL,
      type: 'json',
      contentType: 'application/json',
      method: 'post',
      crossOrigin: true,
      data: JSON.stringify({
        username: username,
        password: password
      })
    })));
  }

  checkAuthentication() {
     return request({
      url: LOGIN_URL,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
  }

  logout() {
    store.dispatch(logoutUser())
  }

  handleAuth(loginPromise) {
    return loginPromise
      .then(function (response) {
        var jwt = response.access_token;
        // LoginActions.loginUser(jwt);
        store.dispatch(loginUser(jwt))
        return true;
      });
  }
}

export default new AuthService()
