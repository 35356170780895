import { LOGIN_USER, LOGOUT_USER } from '../constants/LoginConstants';

export function loginUser(jwt){
  return {
    type: LOGIN_USER,
    jwt: jwt
  }
}

export function logoutUser(){
  return {
    type: LOGOUT_USER
  }
}

// export default {
//   loginUser: (jwt) => {
//     var savedJwt = localStorage.getItem('jwt');

//     AppDispatcher.dispatch({
//       type: LOGIN_USER,
//       jwt: jwt
//     });

//     if (savedJwt !== jwt) {
//       var nextPath = RouterContainer.get().getCurrentQuery().nextPath || '/hjem';

//       RouterContainer.get().transitionTo(nextPath);
//       localStorage.setItem('jwt', jwt);
//     }
//   },
//   logoutUser: () => {
//     RouterContainer.get().transitionTo('/');
//     localStorage.removeItem('jwt');
//     AppDispatcher.dispatch({
//       type: LOGOUT_USER
//     });
//   }
// }
