import { HEATING_GET } from '../constants/HeatingConstants';
import { LOGOUT_USER } from '../constants/LoginConstants';

export function heating(state = { _totalFordelingstal: null, _totalHeathoses: null }, action) {

  switch (action.actionType) {
    case HEATING_GET:
      state._totalFordelingstal = action.heating.totalFordelingstal;
      state._totalHeathoses = action.heating.totalHeatHose;
      return state;
    case LOGOUT_USER:
      state._totalFordelingstal = null;
      state._totalHeathoses = null;
      return state;
    default:
      return state;
  };
}

