import request from 'reqwest';
import when from 'when';
import { APARTMENTS_URL, APARTMENT_URL, APARTMENT_SALE } from '../constants/ApartmentConstants';
import AppDispatcher from '../dispatchers/AppDispatcher.js';
import AuthService from '../services/AuthService'
import { gotApartments, createApartment, changeApartment, saleApartment, deleteApartment, gotApartment } from '../actions/ApartmentActions';
import { store } from '../index';

class ApartmentService {

  getApartments() {
    request({
      url: APARTMENTS_URL,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
      .then(function (response) {
        store.dispatch(gotApartments(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  getApartment(id) {
    request({
      url: APARTMENTS_URL + "/" + id,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
      .then(function (response) {
        store.dispatch(gotApartment(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  newApartment(apartment) {
    return request({
      url: APARTMENT_URL,
      method: 'post',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(apartment)
    })
      .then(function (response) {
        store.dispatch(gotApartment(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  updateApartment(apartment) {
    return request({
      url: APARTMENT_URL + "/" + apartment.id,
      method: 'put',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(apartment)
    })
      .then(function (response) {
        store.dispatch(gotApartment(apartment));
      })
      .catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      })
  }

  saleApartment(apartment) {
    return request({
      url: APARTMENT_SALE + "/" + apartment.id,
      method: 'put',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(apartment)
    }).then(function (response) {
      store.dispatch(gotApartment(response));
    }).catch(function (exception) {
      if (exception.status == 401) {
        AuthService.logout()
      }
    })
  }

  deleteApartment(apartment) {
    return request({
      url: APARTMENT_URL + "/" + apartment.id,
      method: 'delete',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(apartment)
    }).then(function (response) {
      store.dispatch(deleteApartment(response));
    }).catch(function (exception) {
      if (exception.status == 401) {
        AuthService.logout()
      }
    })
  }

}

export default new ApartmentService()
