import React from 'react';
import { store } from '../index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isAuthenticated } from '../helpers/isAuthenticated';

export default ComposedComponent => {
  class AuthenticatedComponent extends React.Component {
    constructor() {
      super()
      this.state = this._getLoginState();
    }

    _getLoginState() {
      return {
        userLoggedIn: !!store.getState().login._jwt,
        user: store.getState().login._user,
        jwt: store.getState().login._jwt
      };
    }
    _onChange() {
      this.setState(this._getLoginState());
    }
    
    render() {
      if (isAuthenticated()) {
        return (
          <ComposedComponent
            {...this.props}
            user={this.state.user}
            jwt={this.state.jwt}
            userLoggedIn={this.state.userLoggedIn} />
        );
      } else {
        if (this.props.location.pathname !== '/login') {
          console.log(this.props.location.pathname);
          this.props.history.push("/login");
        }
      }
      return <React.Fragment></React.Fragment>;
    }
  }
  return withRouter(AuthenticatedComponent);
};
