import React from 'react';
import MoverService from '../../../services/MoverService';
import DatePicker from 'react-date-picker';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { formatDate, formatStringDate, formatStringDateReverse } from '../../../helpers/dateFormatter'
import { parseISO } from 'date-fns';
class MoverComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            edit: false,
            mover: this.props.mover,
            number: this.props.mover.apartment.number
        };
    }

    editClick = () => {
        this.setState({ edit: !this.state.edit })
    }

    requestMovers() {
        return MoverService.get();
    }

    save = () => {
        var newApartment = this.props._apartments.filter(x => x.number === parseInt(this.state.number));

        MoverService.update({ ...this.state.mover, apartment_id: newApartment[0].id, apartment: null });

        this.editClick();
    }

    delete = () => {
        if (window.confirm("er du sikker på at du vil slette " + this.props.mover.seller_name + " fra fraflyttere?")) {
            MoverService.delete(this.props.mover);
        }
    }

    render = () => {

        return (
            <tbody>
                {!this.state.edit ?
                    <tr>
                        <td style={{ textAlign: "center" }}>{formatStringDate(this.props.mover.takeover_date)}</td>
                        <td style={{ textAlign: "center" }}>{this.props.mover.apartment.number}</td>
                        <td>{this.props.mover.seller_name}</td>
                        <td>{this.props.mover.seller_address} {this.props.mover.seller_postcode} {this.props.mover.seller_suburb}</td>
                        <td>{this.props.mover.seller_email}</td>
                        <td>{this.props.mover.buyer_name}</td>
                        <td style={{ whiteSpace: 'nowrap' }}>
                            <button className="btn  pull-right" onClick={this.editClick}>
                                <span className='bi bi-document-text'></span>
                            </button>&nbsp;&nbsp;
                            <button className="btn pull-right" onClick={this.delete.bind(this)}>
                                <span className='bi bi-circle-fill'></span>
                            </button>
                        </td>
                    </tr>
                    :
                    <tr>
                        <td colSpan="9">
                            <div className="panel panel-default">
                                <div className="panel-body textAlignLeft">
                                    <div className="col-md-12">
                                        <form className="apartmentForm" id="apartmentForm" onSubmit={this.save.bind(this)}>
                                            <div className="row">
                                                <div className="col-md-4 panelRowPadding ">
                                                    <div>
                                                        <label>Overtagelsesdato</label>
                                                    </div>
                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        value={this.state.mover.takeover_date ? new Date(parseISO(this.state.mover.takeover_date)) : null}
                                                        onChange={date => { this.setState({ mover: { ...this.state.mover, takeover_date: date.toISOString() } }) }}
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-md-4 panelRowPadding ">
                                                    <div>
                                                        <label>Andels nr.</label>
                                                    </div>
                                                    <input className="form-control" name="type" value={this.state.number} onChange={(event) => this.setState({ number: event.target.value })} type="text" />
                                                </div>
                                                <div className="col-md-4 panelRowPadding ">
                                                    <div>
                                                        <label>Sælgers navn</label>
                                                    </div>
                                                    <input className="form-control" name="type" value={this.state.mover.seller_name} onChange={(event) => this.setState({ mover: { ...this.state.mover, seller_name: event.target.value } })} type="text" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 panelRowPadding ">
                                                    <div>
                                                        <label>Sælgers addresse</label>
                                                    </div>
                                                    <input className="form-control" name="type" value={this.state.mover.seller_address} onChange={(event) => this.setState({ mover: { ...this.state.mover, seller_address: event.target.value } })} type="text" />
                                                </div>
                                                <div className="col-md-4 panelRowPadding ">
                                                    <div>
                                                        <label>Sælgers by</label>
                                                    </div>
                                                    <input className="form-control" name="type" value={this.state.mover.seller_suburb} onChange={(event) => this.setState({ mover: { ...this.state.mover, seller_suburb: event.target.value } })} type="text" />
                                                </div>
                                                <div className="col-md-4 panelRowPadding ">
                                                    <div>
                                                        <label>Sælgers postnummer</label>
                                                    </div>
                                                    <input className="form-control" name="type" value={this.state.mover.seller_postcode} onChange={(event) => this.setState({ mover: { ...this.state.mover, seller_postcode: event.target.value } })} type="text" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 panelRowPadding ">
                                                    <div>
                                                        <label>Sælgers e-mail</label>
                                                    </div>
                                                    <input className="form-control" name="type" value={this.state.mover.seller_email} onChange={(event) => this.setState({ mover: { ...this.state.mover, seller_email: event.target.value } })} type="text" />
                                                </div>
                                                <div className="col-md-4 panelRowPadding ">
                                                    <div>
                                                        <label>Købers navn</label>
                                                    </div>
                                                    <input className="form-control" name="type" value={this.state.mover.buyer_name} onChange={(event) => this.setState({ mover: { ...this.state.mover, buyer_name: event.target.value } })} type="text" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 panelRowPadding">
                                                    <button className="btn btn-primary" onClick={this.save} >Gem</button>
                                                    <button className="btn btn-primary ml-2" onClick={this.editClick}>Afbryd</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                }
            </tbody>
        );
    }
}

function mapStateToProps(state) {
    const { _apartments } = state.apartments;
    return { _apartments };
}

export default withRouter(connect(mapStateToProps)(MoverComponent))