import React from 'react';
import {
  Route,
  Switch,
  useLocation
} from "react-router-dom";
import AuthenticatedApp from './components/AuthenticatedApp';
import News from './components/News';
import Login from './components/Login';
import Home from './components/Home';
import WaitingList from './components/WaitingList/WaitingList';
import WaitingListApply from './components/WaitingList/WaitingListApply';
import HandyManListView from './components/HandyManListView';
import Apartments from './components/Apartments/Apartments';
import Movers from './components/Movers/Movers';
import WaitingListApplications from './components/WaitingList/WaitingListApplications';
import HandyManList from './components/HandyMan/HandyManList';
import GeneralAssembly from './components/GeneralAssembly';
import Heating from './components/Heating';
import Settings from './components/Settings';
import './App.css';
import NewsEditorComponent from './components/NewsEditorComponent';
import WaitingListLetterEditorComponent from './components/WaitingListLetterEditorComponent';
import LetterEditor from './components/LetterEditor';
import { WaitingListApplyThankYou } from './components/WaitingList/WaitingListApplyThankYou';
import { WaitingListApplyError } from './components/WaitingList/WaitingListApplyError';





function App() {
  let query = new URLSearchParams(useLocation().search);

  return (
    <React.Fragment>
      <Switch>
        <Route path="/ansogventeliste" />
        <Route path="*" component={AuthenticatedApp} />
      </Switch>
      <Switch>
        <Route path="/nyheder" component={News} />
        <Route path="/ansogventeliste/tak" component={WaitingListApplyThankYou} />
        <Route path="/ansogventeliste/fejl" component={WaitingListApplyError} />
        <Route path="/ansogventeliste" component={WaitingListApply} />
        <Route path="/login" component={Login} />
        <Route path="/haandvaerkerliste" component={HandyManListView} />
        <Route path="/venteliste" component={WaitingList} />
        <Route path="/hjem" component={Home} />
        <Route path="/lejligheder" component={Apartments} />
        <Route path="/fraflyttere" component={Movers} />
        <Route path='/ventelisteansogninger' component={WaitingListApplications} />
        <Route path='/opretHaandvaerkerliste' component={HandyManList} />
        <Route path="/generalforsamling" component={GeneralAssembly} />
        <Route path="/varmeregnskab" component={Heating} />
        <Route path="/opretNyheder" component={NewsEditorComponent} />
        <Route path="/indstillinger" component={Settings} />
        <Route path="/waitinglistletter" >
          <WaitingListLetterEditorComponent query={query} ></WaitingListLetterEditorComponent>
        </Route>
        <Route path="/letter" >
          <LetterEditor query={query} ></LetterEditor>
        </Route>
        {/* <Redirect to="/nyheder"></Redirect> */}
      </Switch>
    </React.Fragment>
  );
}

export default App;
