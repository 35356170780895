import React from 'react';
import AuthenticatedComponent from '../AuthenticatedComponent';
import WaitingListService from '../../services/WaitingListService.js';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import WaitingListComponent from './subcomponents/WaitingListComponent';

class WaitingListApplications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            waitingList: this.props._applicationWaitingList,
        }
    }

    componentDidMount() {
        if(this.props._applicationWaitingList.length === 0){
            WaitingListService.getApplications();
        }
        if(this.props._waitingList.length === 0){
            WaitingListService.getWaitingList();
        }
    }

    render() {
        if (this.props._applicationWaitingList) {

            var list1 = [];
            var list2 = [];
            var list3 = [];

            this.props._applicationWaitingList.map(function (waitingListEntry) {
                switch (waitingListEntry.listtype.id) {
                    case 1:
                        list1.push(<WaitingListComponent key={waitingListEntry.id} isWaitingList={false} waitingListEntry={waitingListEntry} count={list1.length + 1} ></WaitingListComponent>);
                        break;
                    case 2:
                        list2.push(<WaitingListComponent key={waitingListEntry.id} isWaitingList={false} waitingListEntry={waitingListEntry} count={list2.length + 1} ></WaitingListComponent>);
                        break;
                    case 3:
                        list3.push(<WaitingListComponent key={waitingListEntry.id} isWaitingList={false} waitingListEntry={waitingListEntry} count={list3.length + 1} ></WaitingListComponent>);
                        break;
                    default:
                        break;
                }
            });

        }
        return (
            <React.Fragment>
                <div className="container standardBackground">
                    <div className="row">
                        <div className="col-md">
                            <table className="table table-responsive table-striped table-hover">
                                <thead className='textAlignLeft'>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>#</th>
                                        <th className='textAlignLeft'>Godkend</th>
                                        <th className='textAlignLeft'>Betalt</th>
                                        <th className='textAlignLeft'>Navn</th>
                                        <th className='textAlignLeft'>Adresse</th>
                                        <th className='textAlignLeft'>Email</th>
                                        <th className='textAlignLeft'>Telefon</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list1}
                                    {list2}
                                    {list3}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

function mapStateToProps(state) {
    const { _applicationWaitingList, _waitingList } = state.waitingList;
    return { _applicationWaitingList, _waitingList };
}

export default AuthenticatedComponent(withRouter(connect(mapStateToProps)(WaitingListApplications)))