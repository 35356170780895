import React from 'react';
import { login } from '../reducers/login'
import { Route, Link } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { store } from '../App';
import { loginUser, logoutUser } from '../actions/LoginActions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

class AuthenticatedApp extends React.Component {

  render() {
    return (
      <div className="container">
        <div id="header" className="welcomeHeaderDiv">
          <div >
            <h1 className="welcomeHeader">Vanløsegaard</h1>
          </div>
          <div className="menuBarMargin container">{this.headerItems}</div>
        </div>
      </div>
    );
  }

  logout(e) {
    e.preventDefault();
    AuthService.logout();
  }

  componentDidMount() {
    const self = this;
    AuthService.checkAuthentication()
    .catch(function (exception) {
        AuthService.logout();
        self.props.history.push("/nyheder")
    });
  }

  get headerItems() {
    if (!!this.props._user) {
      return (
        <div className="Navigation col-md mt-3">
          <Link className="Navigation-link" to="hjem">Hjem</Link>
          <Link className="Navigation-link" to="lejligheder">Lejligheder</Link>
          <Link className="Navigation-link" to="fraflyttere">Fraflyttere</Link>
          <Link className="Navigation-link" to="ventelisteansogninger">Ansøgninger</Link>
          <Link className="Navigation-link" to="venteliste">Venteliste</Link>
          <Link className="Navigation-link" to="opretHaandvaerkerliste">Håndværkerliste</Link>
          <Link className="Navigation-link" to="generalforsamling">Generalforsamling</Link>
          <Link className="Navigation-link" to="varmeregnskab">Varmeregnskab</Link>
          <Link className="Navigation-link" to="opretNyheder">Opret nyheder</Link>
          <Link className="Navigation-link" to="indstillinger">Indstillinger</Link>
          <a className="Navigation-link" href="/nyheder" onClick={() => this.props.dispatch(logoutUser())}>Log ud</a>
        </div>
      )
    }
    else {
      return (
        <div className="Navigation col-md mt-3">
          <Link className="Navigation-link" to="nyheder">Nyheder</Link>
          <Link className="Navigation-link" to="ansogventeliste">Venteliste</Link>
          <Link className="Navigation-link" to="haandvaerkerliste">Håndværkerliste</Link>
          <Link className="Navigation-link" to="login">Login</Link>
        </div>)
    }
  }
}

function mapStateToProps(state) {
  const { _user, _jwt } = state.login;
  return { _user, _jwt };
}

export default withRouter(connect(mapStateToProps)(AuthenticatedApp))