import {MOVERS_GET, MOVERS_CREATE, MOVERS_UPDATE, MOVERS_DELETE} from '../constants/MoverConstants';

export function got(movers){
  return {
    type: MOVERS_GET,
    movers:movers
  }
}

export function create(mover){
  return {
    type: MOVERS_CREATE,
    mover:mover
  }
}

export function update(mover){
  return {
    type: MOVERS_UPDATE,
    mover:mover
  }
}

export function rm(mover){
  return {
    type: MOVERS_DELETE,
    mover:mover
  }
}