import React from 'react';
import Auth from '../services/AuthService'
import AuthenticatedComponent from './AuthenticatedComponent';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { store } from '../App';
import RateService from '../services/RateService'
import WaitingListService from '../services/WaitingListService';
import { waitingList } from '../reducers/waitingList';

class Settings extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,

      rate: props._rate,
      saved: false,
      chairman: props._chairman,
      chairmanPass: props._chairman_password,
      treasurer: props._treasurer,
      treasurerPass: props._treasurer_password,
      vicechairman: props._vicechairman,
      vicechairmanPass: props._vicechairman_password,
      secretary: props._secretary,
      secretaryPass: props._secretary_password,

      boardmember1: props._boardmember1,
      boardmember1Pass: props._boardmember1_password,
      boardmember2: props._boardmember2,
      boardmember2Pass: props._boardmember2_password,
      boardmember3: props._boardmember3,
      boardmember3Pass: props._boardmember3_password,
      boardmember4: props._boardmember4,
      boardmember4Pass: props._boardmember4_password,

      board: null,
      boardEditId: null,
      boardNew: false,
      chairmanSaved: false,
      treasurerSaved: false,
      secretarySaved: false,
      vicechairmanSaved: false,
      boardmember1Saved: false,
      boardmember2Saved: false,
      boardmember3Saved: false,
      boardmember4Saved: false
    };

    this.requestRate();
  }
  componentDidMount() {
    if (!this.props._rate) {
      this.requestRate();
    }

    if (!this.props._chairman) {
      this.requestChairman();
    }

    if (!this.props._treasurer) {
      this.requestTreasurer();
    }

    if (!this.props._secretary) {
      this.requestSecretary();
    }

    if (!this.props._vicechairman) {
      this.requestVicechairman();
    }

    if (!this.props._boardmember1) {
      this.requestBoardmember1();
    }

    if (!this.props._boardmember2) {
      this.requestBoardmember2();
    }

    if (!this.props._boardmember3) {
      this.requestBoardmember3();
    }

    if (!this.props._boardmember4) {
      this.requestBoardmember4();
    }
    if (!this.props._board) {
      this.requestBoard();
    }
  }

  static getDerivedStateFromProps(props, state) {
    // Re-run the filter whenever the list array or filter text change.
    // Note we need to store prevPropsList and prevFilterText to detect changes.
    if (
      props.list !== state.prevPropsList ||
      state.prevFilterText !== state.filterText
    ) {
      return {
        prevPropsList: props.list,
        prevFilterText: state.filterText,
        filteredList: props.list.filter(item => item.text.includes(state.filterText))
      };
    }
    return null;
  }

  // getDerivedStateFromProps(props, state) {
  //   console.log("test");

  // }

  getBoardState() {
    return this.props._board;
  }

  getBoardLoadingState() {
    return this.props._boardLoading;
  }

  //START OF STATIC MEMBERS
  getChairmanState() {
    return this.props._chairman;
  }

  getChairmanPassState() {
    return this.props._chairman_password;
  }

  getTreasurerState() {
    return this.props._treasurer;
  }

  getTreasurerPassState() {
    return this.props._treasurer_password;
  }

  getSecretaryState() {
    return this.props._secretary;
  }

  getSecretaryPassState() {
    return this.props._secretary_password;
  }

  getVicechairmanState() {
    return this.props._vicechairman;
  }

  getVicechairmanPassState() {
    return this.props._vicechairman_password;
  }

  getBoardmember1() {
    return this.props._boardmember1;
  }

  getBoardmember1Pass() {
    return this.props._boardmember1_password;
  }

  getBoardmember2() {
    return this.props._boardmember2;
  }

  getBoardmember2Pass() {
    return this.props._boardmember2_password;
  }

  getBoardmember3() {
    return this.props._boardmember3;
  }

  getBoardmember3Pass() {
    return this.props._boardmember3_password;
  }

  getBoardmember4() {
    return this.props._boardmember4;
  }

  getBoardmember4Pass() {
    return this.props._boardmember4_password;
  }

  //END OF STATIC MEMBERS

  getRateState() {
    return this.props._rate;
  }

  requestRate() {
    RateService.ratesGet();
  }

  requestChairman() {
    RateService.memberGet('chairman');
  }

  requestTreasurer() {
    RateService.memberGet('treasurer');
  }

  requestSecretary() {
    RateService.memberGet('secretary');
  }

  requestVicechairman() {
    RateService.memberGet('vicechairman');
  }

  requestBoardmember1() {
    RateService.memberGet('boardmember1');
  }

  requestBoardmember2() {
    RateService.memberGet('boardmember2');
  }

  requestBoardmember3() {
    RateService.memberGet('boardmember3');
  }

  requestBoardmember4() {
    RateService.memberGet('boardmember4');
  }

  requestBoard() {
    RateService.boardGet();
  }

  _onChange() {

  }

  update(e) {
    e.preventDefault();
    RateService.ratePut({ rate: this.state.rate }).catch(function (err) {
      alert("Der opstod en fejl");
      console.log("Error saving", err);
    });
    this.setState({
      saved: true
    })
  }

  updateChairman(e) {
    e.preventDefault();
    RateService.memberPut({
      type: 'chairman',
      value: this.state.chairman,
      password: this.state.chairmanPass
    }).catch(function (err) {
      alert("Der opstod en fejl");
      console.log("Error saving", err);
    });
    this.setState({
      chairmanSaved: true
    });
  }

  updateTreasure(e) {
    e.preventDefault();
    RateService.memberPut(
      {
        type: 'treasurer',
        value: this.state.treasurer,
        password: this.state.treasurerPass
      }
    ).catch(function (err) {
      alert("Der opstod en fejl");
      console.log("Error saving", err);
    });
    this.setState({
      treasurerSaved: true
    });
  }

  updateSecretary(e) {
    e.preventDefault();
    RateService.memberPut({
      type: 'secretary',
      value: this.state.secretary,
      password: this.state.secretaryPass
    }).catch(function (err) {
      alert("Der opstod en fejl");
      console.log("Error saving", err);
    });
    this.setState({
      secretarySaved: true
    });
  }

  updateVicechairman(e) {
    e.preventDefault();
    RateService.memberPut({
      type: 'vicechairman',
      value: this.state.vicechairman,
      password: this.state.vicechairmanPass
    }).catch(function (err) {
      alert("Der opstod en fejl");
      console.log("Error saving", err);
    });
    this.setState({
      vicechairmanSaved: true
    });
  }

  updateBoardmember1(e) {
    e.preventDefault();
    RateService.memberPut({
      type: 'boardmember1',
      value: this.state.boardmember1,
      password: this.state.boardmember1Pass
    }).catch(function (err) {
      alert("Der opstod en fejl");
      console.log("Error saving", err);
    });
    this.setState({
      boardmember1Saved: true
    });
  }

  updateBoardmember2(e) {
    e.preventDefault();
    RateService.memberPut({
      type: 'boardmember2',
      value: this.state.boardmember2,
      password: this.state.boardmember2Pass
    }).catch(function (err) {
      alert("Der opstod en fejl");
      console.log("Error saving", err);
    });
    this.setState({
      boardmember2Saved: true
    });
  }

  updateBoardmember3(e) {
    e.preventDefault();
    RateService.memberPut({
      type: 'boardmember3',
      value: this.state.boardmember3,
      password: this.state.boardmember3Pass
    }).catch(function (err) {
      alert("Der opstod en fejl");
      console.log("Error saving", err);
    });
    this.setState({
      boardmember3Saved: true
    });
  }

  updateBoardmember4(e) {
    e.preventDefault();
    RateService.memberPut({
      type: 'boardmember4',
      value: this.state.boardmember4,
      password: this.state.boardmember4Pass
    }).catch(function (err) {
      alert("Der opstod en fejl");
      console.log("Error saving", err);
    });
    this.setState({
      boardmember4Saved: true
    });
  }


  newBoardMemberClick(e) {
    e.preventDefault();

    if (this.state.board == null) {
      this.state.board = []
    }

    console.log(this.state.board)
    this.state.board.push({
      value: "",
      new: true
    })

    this.setState({
      board: this.state.board
    });
  }

  editBoardMemberClick(entry, e) {
    e.preventDefault();

    this.setState({
      newBoardMemberName: entry.value,
      boardEditId: entry.id
    });
  }

  updateBoardMemberClick(entry, e) {
    e.preventDefault();

    entry.value = this.state.newBoardMemberName

    RateService.updateBoardMember({
      boardmember: entry
    }).catch(function (err) {
      alert("Der opstod en fejl");
      console.log("Error saving", err);
    });

    this.setState({
      newBoardMemberName: null,
      boardMemberLoading: true,
      boardEditId: null
    });
  }

  createBoardMemberClick(entry, e) {
    e.preventDefault();

    RateService.newBoardMember({
      name: this.state.newBoardMemberName
    }).catch(function (err) {
      alert("Der opstod en fejl");
      console.log("Error saving", err);
    });

    this.setState({
      newBoardMemberName: null,
      boardMemberLoading: true,
      boardEditId: null
    });
  }

  rmBoardMemberClick(entry, e) {
    e.preventDefault();

    RateService.deleteBoardMember({
      id: entry.id,
    }).catch(function (err) {
      alert("Der opstod en fejl");
      console.log("Error deleting", err);
    });
  }

  scheduleWaitinglistCleanup(){
    WaitingListService.scheduleCleanup().then((response) => {
      window.alert('Rengøringsjobbet er nu sat til at køre regelmæssigt og det sletter automatisk venteliste ansøgninger som hverken er godkendt eller betalt indenfor få dage. (Ansøgninger på den interne liste vil dog IKKE blive slette automatisk.)');
  });

  }

  render() {
    if (this.state.loading && this.props._boardmember4_password !== null) {
      this.setState({
        loading: false,
        rate: this.getRateState(),
        chairman: this.getChairmanState(),
        chairmanPass: this.getChairmanPassState(),
        treasurer: this.getTreasurerState(),
        treasurerPass: this.getTreasurerPassState(),
        board: this.getBoardState(),
        boardLoading: this.getBoardLoadingState(),
        secretary: this.getSecretaryState(),
        secretaryPass: this.getSecretaryPassState(),
        vicechairman: this.getVicechairmanState(),
        vicechairmanPass: this.getVicechairmanPassState(),
        boardmember1: this.getBoardmember1(),
        boardmember1Pass: this.getBoardmember1Pass(),
        boardmember2: this.getBoardmember2(),
        boardmember2Pass: this.getBoardmember2Pass(),
        boardmember3: this.getBoardmember3(),
        boardmember3Pass: this.getBoardmember3Pass(),
        boardmember4: this.getBoardmember4(),
        boardmember4Pass: this.getBoardmember4Pass()
      });
    }


    var self = this;
    var boardMembers = [];

    if (this.state.board) {
      this.state.board.forEach(function (entry) {
        if ((self.state.boardEditId === entry.id) || (entry.new)) {
          var saveClick = self.state.boardEditId ? self.updateBoardMemberClick : self.createBoardMemberClick;
          boardMembers.push(<tr>
            <td className="col-md-4 boardMemberName">Bestyrelsesmedlem</td>
            <td className="col-md-6" style={{ paddingLeft: '0' }}><input className="form-control" name="type" value={self.state.newBoardMemberName} onChange={(event) => self.setState({ newBoardMemberName: event.target.value })} type="text" /></td>
            <td className="col-md-4" style={{ whiteSpace: 'nowrap' }}>
              <button className="btn btn-primary pull-right" onClick={saveClick.bind(self, entry)}>Gem</button>
            </td>
          </tr>);
        } else {
          boardMembers.push(<tr>
            <td className="col-md-4 boardMemberName">Bestyrelsesmedlem</td>
            <td className="col-md-6" style={{ paddingLeft: '0' }}>{entry.value}</td>
            <td className="col-md-4" style={{ textAlign: "right", whiteSpace: 'nowrap' }}>
              <button className="btn btn-primary" onClick={self.rmBoardMemberClick.bind(self, entry)}>Slet</button>
              &nbsp;
              <button className="btn btn-primary" onClick={self.editBoardMemberClick.bind(self, entry)}>Opdater</button>
            </td>
          </tr>);
        }
      });
    }

    boardMembers.push(<tr>
      <td colSpan="9">
        <button className="btn btn-primary" onClick={self.newBoardMemberClick.bind(self)}>Tilføj</button>
      </td>
    </tr>)

    return (
      <div className="container standardBackground">
        <div className="row">
          <div className=" col-md">
            <h2 className="textAlignCenter">Bestyrelsesmedlemmer</h2>
            <div className="row">
              <form className="col-md-4">
                {this.state.saved && <label className="alert alert-success">Andelskronen er nu opdateret</label>}
                <div className="form-group">
                  <label>Andelskrone (kr.)</label>
                  <input type="text" value={this.state.rate} onChange={(event) => this.setState({ rate: event.target.value })} className=" form-control" />
                </div>
                <div className="form-buttons">
                  <button type="submit" className="btn btn-primary" onClick={this.update.bind(this)}>Opdater</button>
                </div>
              </form>
              <form className="col-md-4">
                {this.state.chairmanSaved && <label className="alert alert-success">Formand er nu opdateret</label>}
                <div className="form-group">
                  <label>Formand</label>
                  <input type="text" value={this.state.chairman} onChange={(event) => this.setState({ chairman: event.target.value })} className=" form-control" />
                  {/* <input type="password" value={this.state.chairmanPass} onChange={(event) => this.setState({ chairmanPass: event.target.value })} className="form-control" /> */}
                </div>
                <div className="form-buttons">
                  <button type="submit" className="btn btn-primary" onClick={this.updateChairman.bind(this)}>Opdater</button>
                </div>
              </form>
              <form className="col-md-4">
                {this.state.vicechairmanSaved && <label className="alert alert-success">Næstformand er nu opdateret</label>}
                <div className="form-group">
                  <label>Næstformand</label>
                  <input type="text" value={this.state.vicechairman} onChange={(event) => this.setState({ vicechairman: event.target.value })} className=" form-control" />
                  {/* <input type="password" value={this.state.vicechairmanPass} onChange={(event) => this.setState({ vicechairmanPass: event.target.value })} className="form-control" /> */}
                </div>
                <div className="form-buttons">
                  <button type="submit" className="btn btn-primary" onClick={this.updateVicechairman.bind(this)}>Opdater</button>
                </div>
              </form>
            </div>
            <div className="row">
              <form className="col-md-4">
                {this.state.treasurerSaved && <label className="alert alert-success">Kassere er nu opdateret</label>}
                <div className="form-group">
                  <label>Kassere</label>
                  <input type="text" value={this.state.treasurer} onChange={(event) => this.setState({ treasurer: event.target.value })} className=" form-control" />
                  {/* <input type="password" value={this.state.treasurerPass} onChange={(event) => this.setState({ treasurerPass: event.target.value })} className=" form-control" /> */}
                </div>
                <div className="form-buttons">
                  <button type="submit" className="btn btn-primary" onClick={this.updateTreasure.bind(this)}>Opdater</button>
                </div>
              </form>
              <form className="col-md-4">
                {this.state.secretarySaved && <label className="alert alert-success">Sekretær er nu opdateret</label>}
                <div className="form-group">
                  <label>Sekretær</label>
                  <input type="text" value={this.state.secretary} onChange={(event) => this.setState({ secretary: event.target.value })} className="form-control" />
                  {/* <input type="password" value={this.state.secretaryPass} onChange={(event) => this.setState({ secretaryPass: event.target.value })} className=" form-control" /> */}
                </div>
                <div className="form-buttons">
                  <button type="submit" className="btn btn-primary" onClick={this.updateSecretary.bind(this)}>Opdater</button>
                </div>
              </form>
              <form className="col-md-4">
                {this.state.boardmember1Saved && <label className="alert alert-success">Bestyrelsesmedlem 1 er nu opdateret</label>}
                <div className="form-group">
                  <label>Bestyrelsesmedlem 1</label>
                  <input type="text" value={this.state.boardmember1} onChange={(event) => this.setState({ boardmember1: event.target.value })} className="form-control" />
                  {/* <input type="password" value={this.state.boardmember1Pass} onChange={(event) => this.setState({ boardmember1Pass: event.target.value })} className=" form-control" /> */}
                </div>
                <div className="form-buttons">
                  <button type="submit" className="btn btn-primary" onClick={this.updateBoardmember1.bind(this)}>Opdater</button>
                </div>
              </form>
            </div>
            <div className="row">
              <form className="col-md-4">
                {this.state.boardmember2Saved && <label className="alert alert-success">Bestyrelsesmedlem 2 er nu opdateret</label>}
                <div className="form-group">
                  <label>Bestyrelsesmedlem 2</label>
                  <input type="text" value={this.state.boardmember2} onChange={(event) => this.setState({ boardmember2: event.target.value })} className="form-control" />
                  {/* <input type="password" value={this.state.boardmember2Pass} onChange={(event) => this.setState({ boardmember2Pass: event.target.value })} className=" form-control" /> */}
                </div>
                <div className="form-buttons">
                  <button type="submit" className="btn btn-primary" onClick={this.updateBoardmember2.bind(this)}>Opdater</button>
                </div>
              </form>
              <form className="col-md-4">
                {this.state.boardmember3Saved && <label className="alert alert-success">Bestyrelsesmedlem 3 er nu opdateret</label>}
                <div className="form-group">
                  <label>Bestyrelsesmedlem 3</label>
                  <input type="text" value={this.state.boardmember3} onChange={(event) => this.setState({ boardmember3: event.target.value })} className="form-control" />
                  {/* <input type="password" value={this.state.boardmember3Pass} onChange={(event) => this.setState({ boardmember3Pass: event.target.value })} className=" form-control" /> */}
                </div>
                <div className="form-buttons">
                  <button type="submit" className="btn btn-primary" onClick={this.updateBoardmember3.bind(this)}>Opdater</button>
                </div>
              </form>
              <form className="col-md-4">
                {this.state.boardmember4Saved && <label className="alert alert-success">Bestyrelsesmedlem 4 er nu opdateret</label>}
                <div className="form-group">
                  <label>Bestyrelsesmedlem 4</label>
                  <input type="text" value={this.state.boardmember4} onChange={(event) => this.setState({ boardmember4: event.target.value })} className="form-control" />
                  {/* <input type="password" value={this.state.boardmember4Pass} onChange={(event) => this.setState({ boardmember4Pass: event.target.value })} className=" form-control" /> */}
                </div>
                <div className="form-buttons">
                  <button type="submit" className="btn btn-primary" onClick={this.updateBoardmember4.bind(this)}>Opdater</button>
                </div>
              </form>
            </div>
            {this.state.boardMembersUpdate &&
              <div>Opdatering vellykket</div>}
            {!this.state.boardMembersUpdate &&
              <div>
                <table className="table boardMemberList">
                  <tbody>{boardMembers}</tbody>
                </table>
              </div>}
          </div>
        </div>
        <div className='row'>
          <div className="col-md">
            <h2 className="textAlignCenter">Avancerede funktioner</h2>
            <p>Brug nedenstående avancerede funktioner med forsigtighed. Altså, tryk kun hvis du ved hvad du gør.</p>

              <h3>Rengøringsjob</h3>
              <div>Skemalæg rengøringsjob. Rengøringsjobbet kører regelmæssigt og sletter alle ikke godkendte ventelisteansøgninger, som ikke er blevet betalt. Ansøgninger til den interne venteliste slettes dog ikke ligesom ansøgninger der er modtaget de seneste dage heller ikke fjernes. Når først jobbet er skeduleret en enkelt gang vil det ikke være nødvendigt at klikke her igen.</div>
              <div><button className="btn btn-primary pull-right" onClick={()=>this.scheduleWaitinglistCleanup()}>Skemalæg nu</button></div>
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { _rate, _board, _boardLoading, _chairman, _chairman_password, _treasurer, _treasurer_password, _secretary, _secretary_password, _vicechairman, _vicechairman_password, _boardmember1, _boardmember1_password, _boardmember2, _boardmember2_password, _boardmember3, _boardmember3_password, _boardmember4, _boardmember4_password } = state.rate;
  return { _rate, _board, _boardLoading, _chairman, _chairman_password, _treasurer, _treasurer_password, _secretary, _secretary_password, _vicechairman, _vicechairman_password, _boardmember1, _boardmember1_password, _boardmember2, _boardmember2_password, _boardmember3, _boardmember3_password, _boardmember4, _boardmember4_password };
}



export default AuthenticatedComponent(withRouter(connect(mapStateToProps)(Settings)))