import React from 'react';
import { withRouter } from 'react-router-dom';
import { formatStringDate } from '../../../helpers/dateFormatter'
import { isThisHour } from 'date-fns';
import ApartmentTenantService from '../../../services/ApartmentTenantService';
import DatePicker from 'react-date-picker';

class EditApartmentTenantComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState()
    }

    getInitialState = () => {
        return {
            apartmentTenant: this.props.apartmentTenant
        };
    }

    save = () => {
        if(this.state.apartmentTenant.id){
            ApartmentTenantService.updateApartmentTenant(this.state.apartmentTenant, this.props.apartment);
        }else {
            ApartmentTenantService.createApartmentTenant(this.state.apartmentTenant, this.props.apartment);
        }
    }

    render = () => {
        return <div className={"row mb-2 " + this.props.warning}>
            <div className="col">
                <div className="row">
                    <div className="col-md-3 panelRowPadding ">
                        <div>
                            <label>Fornavn</label>
                        </div>
                        <input className="form-control" name="type" value={this.state.apartmentTenant.tenant.firstname} onChange={(event) => this.setState({ apartmentTenant: { ...this.state.apartmentTenant, tenant: {...this.state.apartmentTenant.tenant, firstname: event.target.value} } })} type="text" />
                    </div>
                    <div className="col-md-3 panelRowPadding ">
                        <div>
                            <label>Efternavn</label>
                        </div>
                        <input className="form-control" name="type" value={this.state.apartmentTenant.tenant.lastname} onChange={(event) => this.setState({ apartmentTenant: { ...this.state.apartmentTenant, tenant: {...this.state.apartmentTenant.tenant, lastname: event.target.value} } })} type="text" />
                    </div>
                    <div className="col-md-3 panelRowPadding ">
                        <div>
                            <label>Telefon</label>
                        </div>
                        <input className="form-control" name="type" value={this.state.apartmentTenant.tenant.phone} onChange={(event) => this.setState({ apartmentTenant: { ...this.state.apartmentTenant, tenant: {...this.state.apartmentTenant.tenant, phone: event.target.value} } })} type="text" />
                    </div>
                    <div className="col-md-3 panelRowPadding ">
                        <div>
                            <label>Email</label>
                        </div>
                        <input className="form-control" name="type" value={this.state.apartmentTenant.tenant.email} onChange={(event) => this.setState({ apartmentTenant: { ...this.state.apartmentTenant, tenant: {...this.state.apartmentTenant.tenant, email: event.target.value} } })} type="text" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 panelRowPadding ">
                        <label>Indflytningdato</label>
                        <DatePicker dateFormat="dd/MM/yyyy" value={this.state.apartmentTenant.seniority_date ? new Date(this.state.apartmentTenant.seniority_date) : null} onChange={date => { this.setState({ apartmentTenant: { ...this.state.apartmentTenant, seniority_date: date } })}} className="form-control" />
                    </div>
                    <div className="col-md-3 panelRowPadding ">
                        <div>
                            <label>Seneste flytning</label><br />
                        </div>
                        <DatePicker dateFormat="dd/MM/yyyy" value={this.state.apartmentTenant.seniority_date2 ? new Date(this.state.apartmentTenant.seniority_date2) : null} onChange={date => { this.setState({ apartmentTenant: { ...this.state.apartmentTenant, seniority_date2: date } })}} className="form-control" />
                    </div>
                </div>
                <div className="row">
                    <div class="col-2">
                        <button className="btn btn-primary" onClick={() => this.save()}>Gem ændringer</button>
                    </div>
                    <div class="col-2">
                        <button className="btn btn-primary" onClick={() => this.props.toggleEdit()}>Afbryd</button>
                    </div>
                </div>
            </div>
        </div>
    }
}
export default withRouter(EditApartmentTenantComponent);