import React from 'react';
import MoverService from '../../services/MoverService.js';
import AuthenticatedComponent from '../AuthenticatedComponent';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import MoverComponent from './subcomponents/MoverComponent.jsx';
import CreateMoverComponent from './subcomponents/CreateMoverComponent.jsx';
import ApartmentService from '../../services/ApartmentService.js';

class Movers extends React.Component {

  constructor(props) {
    super(props)
    this.state = {};
  }

  componentDidMount() {
    this.requestMovers();
    this.requestApartments();
  }

  requestApartments() {
    if(this.props._apartments.length === 0)
    {
      ApartmentService.getApartments();
    }
  }

  requestMovers() {
    if(this.props._movers.length === 0)
    {
      MoverService.get();
    }
  }

  render() {


    var movers = this.props._movers.map(mover =>
      <MoverComponent mover={mover} key={mover.id}></MoverComponent>
    );

    let moverScaffold = {
      buyer_name: '',
      takeover_date: null,
      seller_email: '',
      seller_postcode: '',
      seller_address: '',
      seller_suburb: '',
      seller_name: '',
      apartment_id: null
    }

    return (
      <React.Fragment>
        <div className="container">
          <div className="col-md">
            <table id="apartmentsTable" className="table table-responsive table-hover table-striped"><thead>

              <tr>
                <th style={{ textAlign: "center" }}>Overtagelsesdato</th>
                <th style={{ textAlign: "center" }}>Andels nr.</th>
                <th className='textAlignLeft'>Sælgers navn</th>
                <th className='textAlignLeft'>Sælgers addresse</th>
                <th className='textAlignLeft'>Sælgers email</th>
                <th className='textAlignLeft'>Købers navn</th>
              </tr>
            </thead>

              {movers}
              <CreateMoverComponent mover={moverScaffold}></CreateMoverComponent>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { _movers } = state.mover;
  const { _apartments } = state.apartments;
  return { _movers, _apartments };
}



export default AuthenticatedComponent(withRouter(connect(mapStateToProps)(Movers)))