import request from 'reqwest';
import {MOVERS_URL} from '../constants/MoverConstants';
import AuthService from '../services/AuthService'
import { got, create, update, rm } from '../actions/MoverActions';
import { store } from '../index';

class MoverService {

  get() {
    console.log('in movers get');
    request({
      url: MOVERS_URL,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
    .then(function(response) {
      store.dispatch(got(response));
    }).catch(function(exception) {
      if (exception.status == 401) {
        AuthService.logout();
      }
    });
  }

  create(mover) {
    return request({
      url: MOVERS_URL,
      method: 'post',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(mover)
    })
    .then(function(response) {
      store.dispatch(create(response));
    }).catch(function(exception) {
      if (exception.status == 401) {
        AuthService.logout();
      }
    });
  }


  update(mover) {
    return request({
      url: MOVERS_URL + "/" + mover.id,
      method: 'put',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(mover)
    }).then(function(response){
      store.dispatch(update(response));
    }).catch(function(exception) {
      if (exception.status == 401) {
        AuthService.logout();
      }
    })
  }

  delete(mover) {
    return request({
      url: MOVERS_URL + "/" + mover.id,
      method: 'delete',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(mover)
    }).then(function(response){
      store.dispatch(rm(response));
    }).catch(function(exception) {
      if (exception.status == 401) {
        AuthService.logout();
      }
    })
  }

}

export default new MoverService()
