import React from 'react';
import ApartmentService from '../../../services/ApartmentService.js';
import { APARTMENT_CERTIFICATE } from '../../../constants/ApartmentConstants';
import { withRouter } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import SellApartmentComponent from './SellApartmentComponent.jsx';
import PrintCertificateComponent from './PrintCertificateComponent.jsx';
import InfoComponent from './InfoComponent';
import UpdateApartmentComponent from './UpdateApartmentComponent.jsx';
import { connect } from 'react-redux';
import ApartmentTenantMoreInfoComponent from './ApartmentTenantMoreInfoComponent.jsx';
import EditApartmentTenantComponent from './EditApartmentTenantComponent.jsx';

class MoreInfoComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            editApartment: false,
            sellApartment: false,
            printCertificate: false,
            certificate_printedDate: null, // type date
            certficate_startDate: null, // type date
            createTenant: false
        };
    };

    splitNumber = function (number) {
        var newNumber = number.replace(/\D/g, '');
        return newNumber.replace(/(.{3})/g, '$1 ').trim();
    };

    handleDate = function (date) {
        return date ? new Date(date) : '';
    };

    handleDateString = function (date) {
        return date ? date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() : '';
    };

    handleClick = function (event) {
        event.target.select();
    };



    requestApartments = function () {
        ApartmentService.getApartments();
    };


    cancelOnSale(e) {
        e.preventDefault();

        if (window.confirm("Vil du afslutte salget af nr:" + this.props.apartment.number)) {
            ApartmentService.saleApartment({ ...this.props.apartment, for_sale: false }).catch(function (err) {
                alert("Der opstod en fejl");
                console.log("Error saving", err);
            });

            this.props.history.push("/fraflyttere?apartment=" + this.props.apartment.number);
        }
    }

    delete(e) {
        e.preventDefault();
        if (window.confirm("Er du sikker på at du vil slette nr:" + this.props.apartment.number)) {
            var id = this.props.apartment.id;
            ApartmentService.deleteApartment({
                id: id
            }).catch(function (err) {
                alert("Der opstod en fejl");
                console.log("Error deleting", err);
            });

            //this.requestApartments();
            this.setState({
                apartments: this.props._apartments,
                apartment: null,
                createNew: false,
            });
        }
    }

    redigerClick = () => {
        if (this.state.editApartment) {
            this.setState(this.getInitialState());
        }
        else {
            this.setState({ editApartment: true });
        }
    };

    salesClick = () => {
        if (this.state.sellApartment) {
            this.setState(this.getInitialState());
        }
        else {
            this.setState({ sellApartment: true });
        }
    };

    printCertificateClick = () => {
        if (this.state.printCertificate) {
            this.setState(this.getInitialState());
        }
        else {
            this.setState({ printCertificate: true });
        }
    };

    toggleCreate = () => {
        this.setState({ createTenant: !this.state.createTenant })
    }

    render() {
        const self = this;

        var tenants = this.props.apartment.apartmentTenants.map(function (apartmentTenant) {
            return (<React.Fragment key={apartmentTenant.id}>
                <ApartmentTenantMoreInfoComponent apartment={self.props.apartment} apartmentTenant={apartmentTenant} warning={self.props.apartment.apartmentTenants.length > 2 ? "table-warning" : ""}></ApartmentTenantMoreInfoComponent>
            </React.Fragment>)
        });

        var createTenantScaffold = { apartment_id: this.props.apartment.id, tenant: { firstname: '', lastname: '', phone: '', email: '' }, seniority_date: null, seniority_date2: null }

        return (
            <tr>
                <td colSpan="7">
                    <div className="row">
                        <div className="col-md-12">
                            {tenants}
                        </div>
                    </div>
                    {
                        this.state.createTenant &&
                        <div className="row">
                            <div className="col-md-12">
                                <EditApartmentTenantComponent apartment={this.props.apartment} apartmentTenant={createTenantScaffold} toggleEdit={this.toggleCreate}></EditApartmentTenantComponent>
                            </div>
                        </div>
                    }
                    {
                        this.props.apartment.apartmentTenants.length < 2 &&
                        <div className="row mb-2">
                            <div className="col-md-12">
                                <button className="btn btn-primary" onClick={this.toggleCreate}>Tilføj beboer</button>
                            </div>
                        </div>
                    }

                    {!this.state.editApartment ?
                        <InfoComponent apartment={this.props.apartment}></InfoComponent>
                        :
                        <UpdateApartmentComponent apartment={this.props.apartment} redigerClick={this.redigerClick}></UpdateApartmentComponent>}
                    <div className="row mt-2">
                        <div className="col-md-12">
                            {!this.state.sellApartment ?
                                <React.Fragment>
                                    {this.props.apartment.for_sale ?
                                        <React.Fragment>
                                            <button className="btn btn-primary" onClick={this.cancelOnSale.bind(this)}>Stop salg</button>
                                            <button className="btn btn-primary ml-2" onClick={this.salesClick}>Rediger pris</button>
                                        </React.Fragment>
                                        :
                                        <button className="btn btn-primary" onClick={this.salesClick}>Sæt til salg</button>}
                                    <button className="btn btn-primary ml-2" onClick={this.delete.bind(this)}>Slet bolig</button>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <button className="btn btn-primary ml-2" onClick={this.delete.bind(this)}>Slet bolig</button>
                                </React.Fragment>}

                            <React.Fragment>
                                <button className="btn btn-primary ml-2" onClick={this.redigerClick}>Rediger</button>
                            </React.Fragment>
                            <React.Fragment>
                                <button className="btn btn-primary ml-2" onClick={this.printCertificateClick}>Print andelsbevis</button>
                            </React.Fragment>
                        </div>
                    </div>
                    <PrintCertificateComponent printCertificate={this.state.printCertificate} apartment={this.props.apartment} printCertificateClick={this.printCertificateClick}></PrintCertificateComponent>
                    <SellApartmentComponent sellApartment={this.state.sellApartment} apartment={this.props.apartment} salesClick={this.salesClick}></SellApartmentComponent>
                </td>
            </tr>
        );
    }
}

export default withRouter(MoreInfoComponent);