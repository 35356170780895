import React from 'react';
import { withRouter } from 'react-router-dom';
import { formatDate, formatStringDate } from '../../../helpers/dateFormatter';
import { connect } from 'react-redux';

class InfoComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }


    render = () => {
        return <div className="row">
            <div className="col-md-12">
                <div className="card card-default">
                    <div className="card-body textAlignLeft">
                        <div className="row">
                            {this.props.apartment.type &&
                                <div className="col-md-3 panelRowPadding">
                                    <label>Type</label><br />
                                    {this.props.apartment.type}
                                </div>}
                            {this.props.apartment.sqm &&
                                <div className="col-md-3 panelRowPadding">
                                    <label>m<sup>2</sup></label><br />
                                    {this.props.apartment.sqm}
                                </div>}
                            {this.props.apartment.garden &&
                                <div className="col-md-3 panelRowPadding">
                                    <label>Have?</label><br />
                                    <span className={this.props.apartment.garden ? 'bi bi-check-circle' : 'bi bi-circle-fill'}>&nbsp;</span>
                                </div>}
                            {this.props.apartment.balcony &&
                                <div className="col-md-3 panelRowPadding">
                                    <label>Altan?</label><br />
                                    <span className={this.props.apartment.balcony ? 'bi bi-check-circle' : 'bi bi-circle-fill'}>&nbsp;</span>
                                </div>}
                            {this.props.apartment.basement &&
                                <div className="col-md-3 panelRowPadding">
                                    <label>Kælderrum</label><br />
                                    {this.props.apartment.basement}
                                </div>}
                            {this.props.apartment.attic &&
                                <div className="col-md-3 panelRowPadding">
                                    <label>Loftrum</label><br />
                                    {this.props.apartment.attic}
                                </div>}
                            {this.props.apartment.apartmentFee &&
                                <div className="col-md-3 panelRowPadding">
                                    <label>Boligafgift</label><br />
                                    {this.props.apartment.apartmentFee}
                                </div>}
                            {this.props.apartment.acontoheat &&
                                <div className="col-md-3 panelRowPadding">
                                    <label>Aconto varme</label><br />
                                    {this.props.apartment.acontoheat}
                                </div>}
                            {this.props.apartment.heathose &&
                                <div className="col-md-3 panelRowPadding">
                                    <label>Varmehaner</label><br />
                                    {this.props.apartment.heathose}
                                </div>}
                            {this.props.apartment.vinduefang !== null &&
                                <div className="col-md-3 panelRowPadding">
                                    <label>Vinduefang</label><br />
                                    {this.props.apartment.vinduefang}
                                </div>}
                            {this.props.apartment.andelskroner &&
                                <div className="col-md-3 panelRowPadding">
                                    <label>Andelskroner</label><br />
                                    {this.props.apartment.andelskroner}
                                </div>}
                            {this.props.apartment.price &&
                                <div className="col-md-3 panelRowPadding">
                                    <label>Max udbuds salgspris</label><br />
                                    {this.props.apartment.price}
                                </div>}

                            {this.props.apartment.andelsbevisDate &&
                                <div className="col-md-3 panelRowPadding">
                                    <label>Andelsbevis sidste print dato</label><br />
                                    {formatStringDate(this.props.apartment.andelsbevisDate)}
                                </div>}

                            {this.props.apartment.company &&
                                <div className="col-md-3 panelRowPadding">
                                    <label>Erhverv?</label><br />
                                    <span className={this.props.apartment.company ? 'bi bi-check-circle' : 'bi bi-circle-fill'}>&nbsp;</span>
                                </div>}
                            {this.props.apartment.note &&
                                <div className="col-md-6 panelRowPadding">
                                    <label>Note</label><br />
                                    {this.props.apartment.note}
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
export default withRouter(InfoComponent);