import request from 'reqwest';
import AuthService from '../services/AuthService'
import { store } from '../index';
import { WAITINGLIST_URL, WAITINGLIST_APPLICATIONS_URL, WAITINGLIST_APPLICATIONS_APPROVED_URL, WAITINGLIST_UPDATE_PAID_URL, WAITINGLIST_START_PAYMENTS_URL, WAITINGLIST_STOP_PAYMENTS_URL, WAITINGLIST_SCHEDULE_CLEANUP_URL, WAITINGLIST_COUNT_URL } from '../constants/WaitingListConstants';
import { gotWaitingList, gotApplicationList, deleteWaitingListEntry, updateWaitingListEntry, updateApplicationListEntry, approveApplicationListEntry, deleteApplicationListEntry } from '../actions/WaitingListActions';


class WaitingListService {
  getWaitingList() {
    request({
      url: WAITINGLIST_URL,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
      .then(function (response) {
        store.dispatch(gotWaitingList(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  updateWaitinglistEntry(waitinglist) {
    return request({
      url: WAITINGLIST_URL + '/' + waitinglist.id,
      method: 'put',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(waitinglist)
    })
      .then(function (response) {
        store.dispatch(updateWaitingListEntry(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  deleteWaitinglistEntry(waitinglist) {
    return request({
      url: WAITINGLIST_URL + '/' + waitinglist.id,
      method: 'delete',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(waitinglist)
    })
      .then(function (response) {
        store.dispatch(deleteWaitingListEntry(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  updateWaitinglistEntryPayment(waitinglist) {
    return request({
      url: WAITINGLIST_UPDATE_PAID_URL,
      method: 'put',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(waitinglist)
    })
      .then(function (response) {
        store.dispatch(updateWaitingListEntry(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  getApplications() {
    request({
      url: WAITINGLIST_APPLICATIONS_URL,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
      .then(function (response) {
        store.dispatch(gotApplicationList(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  updateApplication(waitinglist) {
    return request({
      url: WAITINGLIST_URL + '/' + waitinglist.id,
      method: 'put',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(waitinglist)
    })
      .then(function (response) {
        store.dispatch(updateApplicationListEntry(waitinglist));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  createApplication(waitinglist) {
    return request({
      url: WAITINGLIST_URL,
      method: 'post',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      data: JSON.stringify(waitinglist)
    })
      .then(function (response) {
        return response;
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  deleteApplication(waitinglist) {
    return request({
      url: WAITINGLIST_URL + '/' + waitinglist.id,
      method: 'delete',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(waitinglist)
    })
      .then(function (response) {
        store.dispatch(deleteApplicationListEntry(waitinglist));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  updateApplicationApproval(waitinglist) {
    return request({
      url: WAITINGLIST_APPLICATIONS_APPROVED_URL,
      method: 'put',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(waitinglist)
    })
      .then(function (response) {
        store.dispatch(approveApplicationListEntry(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  startPayment() {
    return request({
      url: WAITINGLIST_START_PAYMENTS_URL,
      method: 'get',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
      .then(function (response) {
        store.dispatch(gotWaitingList(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  stopPayment() {
    return request({
      url: WAITINGLIST_STOP_PAYMENTS_URL,
      method: 'get',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
      .then(function (response) {
        return response;
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  countToBeDeleted() {
    return request({
      url: WAITINGLIST_COUNT_URL,
      method: 'get',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
      .then(function (response) {
        return response;
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  scheduleCleanup() {
    return request({
      url: WAITINGLIST_SCHEDULE_CLEANUP_URL,
      method: 'get',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
      .then(function (response) {
        return response;
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

}

export default new WaitingListService()