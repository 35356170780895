import WaitingListService from '../../../services/WaitingListService'
import React, { Fragment } from 'react'
import AuthenticatedComponent from '../../AuthenticatedComponent'
import { withRouter } from 'react-router-dom'
import WaitingListEntryComponent from './WaitingListEntryComponent'
import { Button } from 'bootstrap'

class WaitingListComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => {
    return {
      showInfo: false,
      paid: this.props.waitingListEntry.paid,
      approved: this.props.waitingListEntry.approved,
    }
  }

  toggleShowInfo = () => {
    this.setState({ showInfo: !this.state.showInfo })
  }

  checkBoxClick = () => {
    if (this.props.isWaitingList) {
      WaitingListService.updateWaitinglistEntryPayment({
        id: this.props.waitingListEntry.id,
        paid: !this.props.waitingListEntry.paid,
      }).catch((err) => {
        this.setState({ paid: this.props.waitingListEntry.paid })
      })
    } else {
      if (
        window.confirm(
          `er du sikker på at du vil godkende ${this.props.waitingListEntry.person.firstname}s ansøgning`,
        )
      ) {
        WaitingListService.updateApplicationApproval({
          id: this.props.waitingListEntry.id,
          approved: !this.props.waitingListEntry.approved,
        }).catch((err) => {
          this.setState({ approved: this.props.waitingListEntry.approved })
        })
      }
    }
  }

  render = () => {
    return (
      <React.Fragment>
        <tr className="according-toggle clickMobile">
          <td style={{ textAlign: 'center' }} onClick={this.toggleShowInfo}>
            {this.props.count} -{' '}
            <span style={{ fontSize: '0.9em' }}>
              {this.props.waitingListEntry.listtype &&
                this.props.waitingListEntry.listtype.letter}
            </span>
          </td>
          {this.props.isWaitingList && this.props.waitingListEntry.initiated && (
            <td>
              <input
                type="checkbox"
                onClick={this.checkBoxClick}
                checked={this.state.paid}
                onChange={(event) =>
                  this.setState({ paid: event.target.checked })
                }
              />
            </td>
          )}
          {this.props.isWaitingList && !this.props.waitingListEntry.initiated && (
            <td>
              <input type="checkbox" checked={true} disabled />
            </td>
          )}
          {!this.props.isWaitingList && (
            <Fragment>
              <td>
                <div className="btn btn-success" onClick={this.checkBoxClick}>Godkend</div>
              </td>
              <td style={{ textAlign: 'center' }}>
                <span
                  className={
                    this.state.paid ? 'bi bi-check-circle' : 'bi bi-circle-fill'
                  }
                >
                  &nbsp;
                </span>
              </td>
            </Fragment>
          )}
          <td onClick={this.toggleShowInfo}>
            {this.props.waitingListEntry.person &&
              this.props.waitingListEntry.person.firstname}{' '}
            {this.props.waitingListEntry.person &&
              this.props.waitingListEntry.person.lastname}
          </td>
          <td onClick={this.toggleShowInfo}>
            {this.props.waitingListEntry.person &&
              this.props.waitingListEntry.person.address}{' '}
            {this.props.waitingListEntry.person &&
              this.props.waitingListEntry.person.postcode}{' '}
            {this.props.waitingListEntry.person &&
              this.props.waitingListEntry.person.suburb}
          </td>
          <td>
            {this.props.waitingListEntry.person != null ? (
              <a
                href={
                  'mailto:' +
                  (this.props.waitingListEntry.person.email != null
                    ? this.props.waitingListEntry.person.email
                    : 'no@email.com') +
                  '?Subject=Kontakt'
                }
                target="_top"
              >
                {this.props.waitingListEntry.person &&
                  this.props.waitingListEntry.person.email}
              </a>
            ) : (
              ''
            )}
          </td>

          <td onClick={this.toggleShowInfo}>
            {this.props.waitingListEntry.person &&
              this.props.waitingListEntry.person.phone}
          </td>
        </tr>
        {this.state.showInfo ? (
          <WaitingListEntryComponent
            isWaitingList={this.props.isWaitingList}
            waitingListEntry={this.props.waitingListEntry}
            toggleShowInfo={this.toggleShowInfo}
          ></WaitingListEntryComponent>
        ) : null}
      </React.Fragment>
    )
  }
}
export default AuthenticatedComponent(withRouter(WaitingListComponent))
