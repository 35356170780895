import React from 'react';
import AuthenticatedComponent from './AuthenticatedComponent'
import { Route, RouteHandler, Link } from 'react-router';
import request from 'reqwest';
import AppDispatcher from '../dispatchers/AppDispatcher.js';
import BASE_URL from '../constants/SystemConstants';
import HeatingService from '../services/HeatingService.js';
import { withRouter } from 'react-router-dom'
import { HEATING_URL } from '../constants/HeatingConstants';
import { connect } from 'react-redux'

class Heating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: false,
      movers: false,
      month: '',
      heatusage: '',
      heattax: '',
      afskrivning: '',
      energycontrol: '',
      energibrand: '',
      heatinghoses: this.getHeatingHoseState(),
      fordelingstal: this.getFordelingstalState()
    };

    this._onChange = this._onChange.bind(this);
  }

  componentDidMount() {
    this.requestBoth();
  }

  getHeatingHoseState() {
    return this.props._totalHeathoses;
  }
  getFordelingstalState() {
    return this.props._totalHeathoses;
  }


  requestBoth() {
    HeatingService.get();
  }

  _onChange() {
    this.setState({
      heatinghoses: this.getHeatingHoseState(),
      fordelingstal: this.getFordelingstalState()
    });
  }

  generateReport() {
    if (this.state.companies) {
      window.location = HEATING_URL + "/" + this.state.month + "/" + this.state.heatusage + "/" + this.state.heattax + "/" + this.state.afskrivning + "/" + this.state.energycontrol + "/" + this.state.energibrand + "/" + this.state.movers + "/erhverv/varmeregnskab";
    }
    else {
      window.location = HEATING_URL + "/" + this.state.month + "/" + this.state.heatusage + "/" + this.state.heattax + "/" + this.state.energycontrol + "/" + this.state.energibrand + "/" + this.state.movers + "/varmeregnskab";
    }
  }

  render() {
    var self = this;
    return (
      <div className="container">
        <div className="row">
          <div className="standardBackground col-md-4 offset-md-4">
            {this.state.heatinghoses &&
              <div className="form-group">
                <label>Totale antal varmehaner</label><br />
                {this.state.heatinghoses}
              </div>}
            {this.state.fordelingstal &&
              <div className="form-group">
                <label>Totale fordelingstal</label><br />
                {this.state.fordelingstal}
              </div>}
            <div className="form-group">
              <label>Fjernvarme - forbrug (kr.)</label>
              <input type="text" value={this.state.heatusage} onChange={(event) => this.setState({ heatusage: event.target.value })} className=" form-control" />
            </div>
            <div className="form-group">
              <label>Fjernvarme - fast afgift (kr.)</label>
              <input type="text" value={this.state.heattax} onChange={(event) => this.setState({ heattax: event.target.value })} className=" form-control" />
            </div>
            <div className="form-group">
              <label>Energistyring (kr.)</label>
              <input type="text" value={this.state.energycontrol} onChange={(event) => this.setState({ energycontrol: event.target.value })} className=" form-control" />
            </div>
            <div className="form-group">
              <label>Energimærke (kr.)</label>
              <input type="text" value={this.state.energibrand} onChange={(event) => this.setState({ energibrand: event.target.value })} className=" form-control" />
            </div>
            <div className="form-group">
              <label>Måned for start af tilbagebetaling over boligafgift</label>
              <input type="text" value={this.state.month} onChange={(event) => this.setState({ month: event.target.value })} className=" form-control" />
            </div>
            <div className="form-group">
              <label className="col-md-12 marginLeft1" >Skal der laves regnskab for fraflyttere?</label>
              <input className="col-md-12" type="checkbox" checked={this.state.movers} onChange={(event) => this.setState({ movers: event.target.checked })} />
            </div>
            <div className="form-group">
              <label className="col-md-12 marginLeft1" >Skal der laves regnskab for erhverv?</label>
              <input className="col-md-12" type="checkbox" checked={this.state.companies} onChange={(event) => this.setState({ companies: event.target.checked })} />
            </div>
            {this.state.companies &&
              <div>
                <div className="form-group">
                  <label>Afskrivning af varmeanlæg (kr.)</label>
                  <input type="text" value={this.state.afskrivning} onChange={(event) => this.setState({ afskrivning: event.target.value })} className=" form-control" />
                </div>
              </div>
            }
            <div className="form-group slightMarginTopApartments">
              <button className="btn btn-primary" onClick={self.generateReport.bind(this)}>Generer varmeregnskab</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { _totalFordelingstal, _totalHeathoses } = state.heating;
  return { _totalFordelingstal, _totalHeathoses };
}



export default AuthenticatedComponent(withRouter(connect(mapStateToProps)(Heating)))