import React from 'react';
import ApartmentService from '../../../services/ApartmentService';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import { connect } from 'react-redux';
import { store } from '../../../App';
class UpdateApartmentComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            apartment: this.props.apartment,
        };
    };

    updateApartment(e) {
        e.preventDefault();
        this.props.redigerClick();

        ApartmentService
            .updateApartment(this.state.apartment)
            .catch(function (err) {
                alert("Der opstod en fejl");
                console.log("Error saving", err);
            });

        // this.setState({})
        // this.setState(this.getInitialState());

        return;
    }

    render = () => {
        return <div className="row">
            <div className="col-md-12">
                <div className="panel panel-default">
                    <div className="panel-body textAlignLeft">
                        <form className="apartmentForm" id="apartmentForm" onSubmit={this.updateApartment.bind(this)}>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>Andels nr.</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.apartment.number} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, number: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>Adresse</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.apartment.address} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, address: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>Postnummer</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.apartment.postcode} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, postcode: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>By</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.apartment.suburb} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, suburb: event.target.value } })} type="text" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 panelRowPadding ">
                                        <label>Type</label>
                                        <input className="form-control" name="type" value={this.state.apartment.type} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, type: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>m<sup>2</sup></label><br />
                                        </div>
                                        <input className="form-control" name="type" value={this.state.apartment.sqm} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, sqm: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>Have?</label><br />
                                        </div>
                                        <input type="checkbox" checked={this.state.apartment.garden} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, garden: event.target.checked } })} />
                                    </div>
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>Altan?</label><br />
                                        </div>
                                        <input type="checkbox" checked={this.state.apartment.balcony} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, balcony: event.target.checked } })} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>Kælderrum</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.apartment.basement} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, basement: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>Loftrum</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.apartment.attic} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, attic: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>Boligafgift</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.apartment.apartmentFee} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, apartmentFee: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>Aconto varme</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.apartment.acontoheat} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, acontoheat: event.target.value } })} type="text" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>Varmehaner</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.apartment.heathose} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, heathose: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>Vinduefang</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.apartment.vinduefang} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, vinduefang: event.target.value } })} type="text" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>Antal andelskroner</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.apartment.andelskroner} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, andelskroner: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-3 panelRowPadding ">
                                        <div>
                                            <label>Erhverv?</label><br />
                                        </div>
                                        <input type="checkbox" checked={this.state.apartment.company} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, company: event.target.value } })} />
                                    </div>
                                    <div className="col-md-6 panelRowPadding ">
                                        <div>
                                            <label>Note</label>
                                        </div>
                                        <textarea className="form-control" rows="2" id="comment" value={this.state.apartment.note} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, note: event.target.value } })}></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-offset-6  col-md-3 panelRowPadding">
                                        <button type="button" className="btn btn-primary" onClick={this.props.redigerClick}>Afbryd</button>
                                    </div>
                                    <div className="col-md-3 panelRowPadding">
                                        <button className="btn btn-primary" type="submit">Gem</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                    <hr></hr>
                </div>
            </div>
        </div>
    }
}
export default withRouter(UpdateApartmentComponent);