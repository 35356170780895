import { HANDYMAN_PRIVATE_GET, HANDYMAN_PUBLIC_GET, HANDYMAN_PRIVATE_UPDATE, HANDYMAN_PUBLIC_UPDATE, HANDYMAN_RM, HANDYMAN_ADD_PRIVATE, HANDYMAN_ADD_PUBLIC } from '../constants/HandyManConstants';
import { LOGOUT_USER } from '../constants/LoginConstants';

export function handymen(state = { _private: [], _public: [] }, action) {
  switch (action.type) {
    case HANDYMAN_PRIVATE_GET:
      return { ...state, _private: action.handyManList }
    case HANDYMAN_PUBLIC_GET:
      return { ...state, _public: action.handyManList }
    case HANDYMAN_PRIVATE_UPDATE:
      {
        let tempPrivate = state._private.filter(x => x.id !== null);
        tempPrivate.push(action.handyMan);
        return { ...state, _private: tempPrivate}
      }
    case HANDYMAN_PUBLIC_UPDATE:
      {
        let tempPublic = state._public.filter(x => x.id !== null);
        tempPublic.push(action.handyMan);
        return { ...state, _public: tempPublic }
      }
    case LOGOUT_USER:
      return {...state, _public: [], _private: []};
    case HANDYMAN_RM:
      return { ...state, _public: state._public.filter(x => x.id !== action.handyMan.id), _private: state._private.filter(x => x.id !== action.handyMan.id) };
    case HANDYMAN_ADD_PRIVATE:
      let tempPrivate = state._private.filter(x => !!x.id);
      tempPrivate.push(action.handyMan);
      return { ...state, _private: tempPrivate }
    case HANDYMAN_ADD_PUBLIC:
      let tempPublic = state._public.filter(x => !!x.id);
      tempPublic.push(action.handyMan);
      return { ...state, _public: tempPublic }
    default:
      return state;
  };
}