import React from 'react';
import DatePicker from 'react-date-picker';
import { withRouter } from 'react-router-dom';
import { formatDateReverse } from '../../../helpers/dateFormatter';
import { APARTMENT_CERTIFICATE } from '../../../constants/ApartmentConstants';
import ApartmentService from '../../../services/ApartmentService';

class PrintCertificateComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return ({
            printError: null,
            certficate_startDate: null,
            certificate_printedDate: null
        });
    }

    clickGenerateApartmentCertificate(e) {
        e.preventDefault();
        if (this.state.certficate_startDate === null || this.state.certificate_printedDate === null) {
            this.setState({ printError: 'Venligst udfyld alle felterne' });
            return;
        }

        this.generateApartmentCertificate(this.props.apartment.id, formatDateReverse(this.state.certficate_startDate), formatDateReverse(this.state.certificate_printedDate));

        ApartmentService.updateApartment({...this.props.apartment, andelsbevisDate: new Date(Date.now()).toISOString()})
        .catch(function (err) {
            alert("Der opstod en fejl");
            console.log("Error saving", err);
        });

        this.props.printCertificateClick();
    }

    generateApartmentCertificate(apartmentId, startDate, printedDate) {
        window.open(APARTMENT_CERTIFICATE + apartmentId + "/" + startDate.replace(/\//g, '-') + "/" + printedDate.replace(/\//g, '-'), '_blank' // <- This is what makes it open in a new window.
        );
    }

    render = () => {
        return <React.Fragment>
            {this.props.printCertificate &&
                <form className="apartmentForm " id="apartmentForm">
                    <div className='col-md-12 slightMarginTopApartments'>
                        <div className="col-md-3 panelRowPadding">
                            {this.state.printError && <div className="printError">{this.state.printError}</div>}
                        </div>
                    </div>
                    <div className='col-md-12'>

                        <div className="col-md-3 panelRowPadding ">
                            <div>
                                <label>Indtrædelsesdato</label>
                            </div>
                            <DatePicker dateFormat="dd/MM/yyyy" value={this.state.certficate_startDate} onChange={date => { this.setState({ certficate_startDate: date }); }} className="form-control" />

                        </div>
                        <div className="col-md-3 panelRowPadding ">
                            <div>
                                <label>Udstedelsesdato</label>
                            </div>
                            <DatePicker dateFormat="dd/MM/yyyy" value={this.state.certificate_printedDate} onChange={date => { this.setState({ certificate_printedDate: date }); }} className="form-control" />

                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className="col-md-offset-6 col-md-3 panelRowPadding">
                            <button className="btn btn-primary" onClick={this.printCertificateClick}>Afbryd</button>
                        </div>
                        <div className="col-md-3 panelRowPadding">
                            <button className="btn btn-primary" onClick={this.clickGenerateApartmentCertificate.bind(this)}>Print bevis</button>
                        </div>
                    </div>
                </form>}
        </React.Fragment>
    }
}
export default withRouter(PrintCertificateComponent);