import request from 'reqwest';
import AuthService from './AuthService'
import { store } from '../index';
import { PERSON_URL } from '../constants/PersonConstants';


class PersonService {
  get(id) {
    return request({
      url: PERSON_URL + '/' + id,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
    .then(function(response) {
      return response;
    }).catch(function(exception) {
      if (exception.status == 401) {
        AuthService.logout()
      }
    });
  }

  delete(person){
      return request({
        url: PERSON_URL + '/' + person.id,
        method: 'delete',
        type: 'json',
        contentType: 'application/json',
        crossOrigin: true,
        headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
        },
        data: JSON.stringify(person)
    })
    .catch(function(exception) {
      if (exception.status == 401) {
        AuthService.logout()
      }
    });
  }

  update(person){
    return request({
      url: PERSON_URL + '/' + person.id,
        method: 'put',
        type: 'json',
        contentType: 'application/json',
        crossOrigin: true,
        headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
        },
        data: JSON.stringify(person)
    })
    .then(function(response) {
      return response;
    }).catch(function(exception) {
      if (exception.status == 401) {
        AuthService.logout()
      }
    });
  }
}

export default new PersonService()