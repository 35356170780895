import { store } from '../index';
import jwt_decode from 'jwt-decode';
import { logoutUser } from '../actions/LoginActions';

export function isAuthenticated() {
    let jwt = store.getState().login._jwt;
    if(!!jwt){
        let decoded = jwt_decode(jwt);
        const now = Date.now() / 1000
        if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
            store.dispatch(logoutUser())
            return false;
            //throw new Error(`token expired: ${JSON.stringify(decoded)}`)
        }
        if (typeof decoded.nbf !== 'undefined' && decoded.nbf > now) {
            store.dispatch(logoutUser())
            return false;
            //throw new Error(`token not yet valid: ${JSON.stringify(decoded)}`)
        }
        return true;
    } else {
        return false;
    }
}