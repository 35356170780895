import request from 'reqwest';
import when from 'when';
import { NEWS_PUBLIC, NEWS_ADMIN } from '../constants/NewsConstants.js';
import { adminUpdate, gotAdminNews, gotPublicNews, publicUpdate, deleteAdminNews, deletePublicNews } from '../actions/NewsActions.js';
import AppDispatcher from '../dispatchers/AppDispatcher.js';
import { connect } from 'react-redux'
import AuthService from './AuthService';
import { store } from '../index';

class NewsService {

  publicNewsPost(news) {
    return request({
      url: NEWS_PUBLIC,
      method: 'post',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(news)
    }).catch(function (exception) {
      if (exception.status == 401) {
        AuthService.logout()
      }
    });
  }

  publicNewsGet() {
    request({
      url: NEWS_PUBLIC,
      method: 'get',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true
    }).then(function (response) {
      store.dispatch(gotPublicNews(response));
      // NewsActions.gotPublicNews(response);
    });
  }

  publicNewsPut(news) {
    return request({
      url: NEWS_PUBLIC,
      method: 'put',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(news)
    }).then(function (response) {
      store.dispatch(publicUpdate(response));
      // NewsActions.publicUpdate(response);
    }).catch(function (exception) {
      if (exception.status == 401) {
        AuthService.logout()
      }
    });
  }

  publicNewsDelete(news) {
    return request({
      url: NEWS_PUBLIC + '/' + news.id,
      method: 'delete',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(news)
    }).then(function (response) {
      store.dispatch(deletePublicNews(response));
    })
      .catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  adminNewsPost(news) {
    return request({
      url: NEWS_ADMIN,
      method: 'post',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(news)
    }).catch(function (exception) {
      if (exception.status == 401) {
        AuthService.logout()
      }
    });
  }

  adminNewsGet() {
    request({
      url: NEWS_ADMIN,
      method: 'get',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    }).then(function (response) {
      store.dispatch(gotAdminNews(response));
    });
  }

  adminNewsPut(news) {
    return request({
      url: NEWS_ADMIN,
      method: 'put',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(news)
    }).then(function (response) {
      store.dispatch(adminUpdate(response));
    });
  }

  adminNewsDelete(news) {
    return request({
      url: NEWS_ADMIN + '/' + news.id,
      method: 'delete',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(news)
    }).then(function (response) {
      store.dispatch(deleteAdminNews(response));
    });
  }
}

export default new NewsService();