import React from 'react';
import { withRouter } from 'react-router-dom';
import ApartmentService from '../../../services/ApartmentService';
import { connect } from 'react-redux';
class CreateApartmentComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            createNewApartment: false,
            newApartment: {}
        }
    }

    requestApartments = function () {
        ApartmentService.getApartments();
    }

    createNewApartmentClick = () => {
        if (this.state.createNewApartment) {
            this.setState({ createNewApartment: false });
            this.state = this.getInitialState();
        }
        else {
            this.setState({ createNewApartment: true, newApartment: {} });
        }
    }

    createNewApartment(e) {
        // lets not submit the form.
        e.preventDefault();

        // because we are in this component we know that we are creating a new apartment.
        ApartmentService
            .newApartment(this.state.newApartment)
            .catch(function (err) {
                alert("Der opstod en fejl");
                console.log("Error saving", err);
            });

        // 
        this.setState(this.getInitialState());

        return;
    }

    render = function () {
        return (
            <div className="col-md">
                {this.state.createNewApartment ?
                    <form className="apartmentForm " id="apartmentForm" onSubmit={this.createNewApartment.bind(this)} >
                        <table id="apartmentEdit" className="table table-responsive"><tbody>
                            <tr>
                                <td>
                                    <label>Andels nr.</label><br />
                                    <input className='form-control' type="text" value={this.state.newApartment.number} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, number: event.target.value } })} />
                                </td>
                                <td>
                                    <label>Adresse</label><br />
                                    <input className='form-control' type="text" value={this.state.newApartment.address} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, address: event.target.value } })} />
                                </td>
                                <td>
                                    <label>Postnummer</label><br />
                                    <input className='form-control' type="text" value={this.state.newApartment.postcode} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, postcode: event.target.value } })} />
                                </td>
                                <td>
                                    <label>By</label><br />
                                    <input className='form-control' type="text" value={this.state.newApartment.suburb} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, suburb: event.target.value } })} />
                                </td>
                                <td>
                                    <label>Type</label><br />
                                    <input className='form-control' type="text" value={this.state.newApartment.type} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, type: event.target.value } })} />
                                </td>
                                <td>
                                    <label>Størrelse</label><br />
                                    <input className='form-control' type="text" value={this.state.newApartment.sqm} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, sqm: event.target.value } })} />
                                </td>
                            </tr>
                            <tr>

                                <td >
                                    <label>Have?</label><br />
                                    <input className='' type="checkbox" checked={this.state.garden} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, garden: event.target.checked } })} />
                                </td>
                                <td >
                                    <label>Altan?</label><br />
                                    <input className='' type="checkbox" checked={this.state.balcony} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, balcony: event.target.checked } })} />
                                </td>
                                <td >
                                    <label>Kælderrum</label><br />
                                    <input className='form-control' type="text" value={this.state.basement} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, basement: event.target.value } })} />
                                </td>
                                <td >
                                    <label>Loftrum</label><br />
                                    <input className='form-control' type="text" value={this.state.attic} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, attic: event.target.value } })} />
                                </td>
                                <td >
                                    <label>Boligafgift 1</label><br />
                                    <input className='form-control' type="text" value={this.state.apartmentFee} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, apartmentFee: event.target.value } })} />
                                </td>

                                <td >
                                    <label>Aconto varme</label><br />
                                    <input className='form-control' type="text" value={this.state.acontoheat} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, acontoheat: event.target.value } })} />
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <label>Varmehaner</label><br />
                                    <input className='form-control' type="text" value={this.state.heathose} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, heathose: event.target.value } })} />
                                </td>
                                <td >
                                    <label>Vinduefang</label><br />
                                    <input className='form-control' type="text" value={this.state.vinduefang} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, vinduefang: event.target.value } })} />
                                </td>
                            </tr>
                            <tr>

                                <td >
                                    <label>Andelskroner</label><br />
                                    <input className='form-control' type="text" value={this.state.andelskroner} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, andelskroner: event.target.value } })} />
                                </td>
                                <td >
                                    <label>Erhverv?</label><br />
                                    <input className='' type="checkbox" checked={this.state.company} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, company: event.target.value } })} />
                                </td>
                                <td colSpan="2">
                                    <label>Noter</label><br />
                                    <input className='form-control' type="text" value={this.state.note} onChange={(event) => this.setState({ newApartment: { ...this.state.newApartment, note: event.target.value } })} />
                                </td>

                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <button className="btn btn-primary" onClick={this.createNewApartmentClick}>Afbryd</button>
                                </td>
                                <td>
                                    <button type="submit" className="btn btn-primary" >Opret</button>
                                </td>

                            </tr>
                        </tbody>
                        </table>

                    </form> :
                    <div className='midButtonWithPadding'><button className="btn btn-primary" onClick={this.createNewApartmentClick}>Opret ny lejlighed</button></div>}
            </div>
        );
    }
}

export default withRouter(CreateApartmentComponent);