import request from "reqwest";
import when from "when";
import ApartmentActions from "../actions/ApartmentActions";
import AppDispatcher from "../dispatchers/AppDispatcher.js";
import AuthService from "../services/AuthService";
import { store } from "../index";
import { SEND_COOPOWNER_NOTICE, SEND_WAITINGLIST_NOTICE, SEND_WAITINGLIST_PAYMENT_NOTICE, SEND_WAITINGLIST_PAYMENT_LATE_NOTICE } from "../constants/EmailConstants";

class EmailService {
  sendCoopOwnerEmail(email) {
    return request({
      url: SEND_COOPOWNER_NOTICE,
      method: "post",
      type: "json",
      contentType: "application/json",
      crossOrigin: true,
      headers: {
        Authorization: "Bearer " + store.getState().login._jwt,
      },
      data: JSON.stringify(email),
    }).catch(function (exception) {
      if (exception.status == 401) {
        AuthService.logout();
      }
    });
  }

  sendWaitingListEmail(email) {
    return request({
      url: SEND_WAITINGLIST_NOTICE,
      method: "post",
      type: "json",
      contentType: "application/json",
      crossOrigin: true,
      headers: {
        Authorization: "Bearer " + store.getState().login._jwt,
      },
      data: JSON.stringify(email),
    }).catch(function (exception) {
      if (exception.status == 401) {
        AuthService.logout();
      }
    });
  }
  sendWaitingListPaymentNotice(email) {
    return request({
      url: SEND_WAITINGLIST_PAYMENT_NOTICE,
      method: "post",
      type: "json",
      contentType: "application/json",
      crossOrigin: true,
      headers: {
        Authorization: "Bearer " + store.getState().login._jwt,
      },
      data: JSON.stringify(email),
    }).catch(function (exception) {
      if (exception.status == 401) {
        AuthService.logout();
      }
    });
  }
  sendWaitingListPaymentLateNotice(email) {
    return request({
      url: SEND_WAITINGLIST_PAYMENT_LATE_NOTICE,
      method: "post",
      type: "json",
      contentType: "application/json",
      crossOrigin: true,
      headers: {
        Authorization: "Bearer " + store.getState().login._jwt,
      },
      data: JSON.stringify(email),
    }).catch(function (exception) {
      if (exception.status == 401) {
        AuthService.logout();
      }
    });
  }
}

export default new EmailService();
