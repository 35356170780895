import {BASE_URL} from '../constants/SystemConstants';

export const APARTMENTS_URL = BASE_URL + 'apartments';
export const APARTMENTS_GET = 'APARTMENTS_GET';
export const APARTMENT_GET = 'APARTMENT_GET';
export const APARTMENT_URL = BASE_URL + 'apartments';
export const APARTMENT_SALE = BASE_URL + 'apartments/sale';
export const APARTMENT_CERTIFICATE = BASE_URL + "certificate/";
export const APARTMENT_DELETE = 'APARTMENT_DELETE';
export const APARTMENT_CHECK_REPORT = 'APARTMENT_CHECK_REPORT';
