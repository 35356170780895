import React from 'react';
import { withRouter } from 'react-router-dom';
import AuthenticatedComponent from '../../AuthenticatedComponent';
import DatePicker from 'react-date-picker';
import { parseISO } from 'date-fns';
import WaitingListService from '../../../services/WaitingListService';
import PersonService from '../../../services/PersonService';

class EditWaitingListEntryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            waitingListEntry: this.props.waitingListEntry,
            referer: this.props.referer
        }
    }

    save = () => {
        if (this.props.isWaitingList) {
            if (this.state.waitingListEntry.referer_id && this.state.waitingListEntry.waiting_list_type_id === 2) {
                PersonService.update(this.state.referer);
                this.props.updateReferer(this.state.referer);
                WaitingListService.updateWaitinglistEntry(this.state.waitingListEntry);
            } else {
                WaitingListService.updateWaitinglistEntry(this.state.waitingListEntry);
            }
        } else {
            if (this.state.waitingListEntry.referer_id && this.state.waitingListEntry.waiting_list_type_id === 2) {
                PersonService.update(this.state.referer);
                this.props.updateReferer(this.state.referer);
                WaitingListService.updateApplication(this.state.waitingListEntry);
            } else {
                WaitingListService.updateApplication(this.state.waitingListEntry);
            }
        }


        this.props.redigerClick();
    }

    render = () => {
        return (
            <td colSpan="6">
                <div className="panel panel-default">
                    <div className="panel-body textAlignLeft" >
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-3 panelRowPadding ">
                                    <div>
                                        <label>Fornavn</label>
                                    </div>
                                    <input className="form-control" name="type" value={this.state.waitingListEntry.person.firstname} onChange={(event) => this.setState({ waitingListEntry: { ...this.state.waitingListEntry, person: { ...this.state.waitingListEntry.person, firstname: event.target.value } } })} type="text" />
                                </div>
                                <div className="col-md-3 panelRowPadding ">
                                    <div>
                                        <label>Efternavn</label>
                                    </div>
                                    <input className="form-control" name="type" value={this.state.waitingListEntry.person.lastname} onChange={(event) => this.setState({ waitingListEntry: { ...this.state.waitingListEntry, person: { ...this.state.waitingListEntry.person, lastname: event.target.value } } })} type="text" />
                                </div>

                                <div className="col-md-3 panelRowPadding ">
                                    <div>
                                        <label>Address</label>
                                    </div>
                                    <input className="form-control" name="type" value={this.state.waitingListEntry.person.address} onChange={(event) => this.setState({ waitingListEntry: { ...this.state.waitingListEntry, person: { ...this.state.waitingListEntry.person, address: event.target.value } } })} type="text" />
                                </div>
                                <div className="col-md-3 panelRowPadding ">
                                    <div>
                                        <label>By</label>
                                    </div>
                                    <input className="form-control" name="type" value={this.state.waitingListEntry.person.suburb} onChange={(event) => this.setState({ waitingListEntry: { ...this.state.waitingListEntry, person: { ...this.state.waitingListEntry.person, suburb: event.target.value } } })} type="text" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 panelRowPadding ">
                                    <div>
                                        <label>Postnummer</label>
                                    </div>
                                    <input className="form-control" name="type" value={this.state.waitingListEntry.person.postcode} onChange={(event) => this.setState({ waitingListEntry: { ...this.state.waitingListEntry, person: { ...this.state.waitingListEntry.person, postcode: event.target.value } } })} type="text" />
                                </div>
                                <div className="col-md-3 panelRowPadding ">
                                    <div>
                                        <label>E-mail</label>
                                    </div>
                                    <input className="form-control" name="type" value={this.state.waitingListEntry.person.email} onChange={(event) => this.setState({ waitingListEntry: { ...this.state.waitingListEntry, person: { ...this.state.waitingListEntry.person, email: event.target.value } } })} type="text" />
                                </div>
                                <div className="col-md-3 panelRowPadding ">
                                    <div>
                                        <label>Telefon</label>
                                    </div>
                                    <input className="form-control" name="type" value={this.state.waitingListEntry.person.phone} onChange={(event) => this.setState({ waitingListEntry: { ...this.state.waitingListEntry, person: { ...this.state.waitingListEntry.person, phone: event.target.value } } })} type="text" />
                                </div>
                                <div className="col-md-3 panelRowPadding ">
                                    <div>
                                        <label>Dato for indskrivelse</label>
                                    </div>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        value={parseISO(this.state.waitingListEntry.entry_date)}
                                        onChange={date => this.setState({ waitingListEntry: { ...this.state.waitingListEntry, entry_date: date.toISOString() } })}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            {this.props.waitingListEntry.waiting_list_type_id === 2 &&
                                <div>
                                    <div className="row">
                                        <div className="col-md-3 panelRowPadding ">
                                            <div>
                                                <label>Referere Fornavn</label>
                                            </div>
                                            <input className="form-control" name="type" value={this.state.referer.firstname} onChange={(event) => this.setState({ referer: { ...this.state.referer, firstname: event.target.value } })} type="text" />
                                        </div>
                                        <div className="col-md-3 panelRowPadding ">
                                            <div>
                                                <label>Referere Efternavn</label>
                                            </div>
                                            <input className="form-control" name="type" value={this.state.referer.lastname} onChange={(event) => this.setState({ referer: { ...this.state.referer, lastname: event.target.value } })} type="text" />
                                        </div>
                                        <div className="col-md-3 panelRowPadding ">
                                            <div>
                                                <label>Referere Address</label>
                                            </div>
                                            <input className="form-control" name="type" value={this.state.referer.address} onChange={(event) => this.setState({ referer: { ...this.state.referer, address: event.target.value } })} type="text" />
                                        </div>
                                        <div className="col-md-3 panelRowPadding ">
                                            <div>
                                                <label>Referere By</label>
                                            </div>
                                            <input className="form-control" name="type" value={this.state.referer.suburb} onChange={(event) => this.setState({ referer: { ...this.state.referer, suburb: event.target.value } })} type="text" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 panelRowPadding ">
                                            <div>
                                                <label>Referere Postnummer</label>
                                            </div>
                                            <input className="form-control" name="type" value={this.state.referer.postcode} onChange={(event) => this.setState({ referer: { ...this.state.referer, postcode: event.target.value } })} type="text" />
                                        </div>
                                        <div className="col-md-3 panelRowPadding ">
                                            <div>
                                                <label>Referere Email</label>
                                            </div>
                                            <input className="form-control" name="type" value={this.state.referer.email} onChange={(event) => this.setState({ referer: { ...this.state.referer, email: event.target.value } })} type="text" />
                                        </div>
                                        <div className="col-md-3 panelRowPadding ">
                                            <div>
                                                <label>Referere Telefon</label>
                                            </div>
                                            <input className="form-control" name="type" value={this.state.referer.phone} onChange={(event) => this.setState({ referer: { ...this.state.referer, phone: event.target.value } })} type="text" />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-md-6 panelRowPadding ">
                                    <div>
                                        <label>Noter</label>
                                    </div>
                                    <textarea className="form-control" rows="2" id="comment" value={this.state.waitingListEntry.note} onChange={(event) => this.setState({ waitingListEntry: { ...this.state.waitingListEntry, person: { ...this.state.waitingListEntry.person, note: event.target.value } } })} ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="col-md-offset-6  col-md-3 panelRowPadding">
                                <button className="btn btn-primary" onClick={this.props.redigerClick}>Afbryd</button>
                            </div>
                            <div className="col-md-3 panelRowPadding">
                                <button className="btn btn-primary" onClick={this.save}>Gem</button>
                            </div>
                        </div>
                    </div>
                </div>
            </td>)
    }
}

export default AuthenticatedComponent(withRouter(EditWaitingListEntryComponent));