import React from 'react';
import { withRouter } from 'react-router-dom';
import ApartmentService from '../../../services/ApartmentService';

class SellApartmentComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        var temp = this.props.apartment;
        if(!temp.improvements){
            temp.improvements = 0;
        }
        if(!temp.adjusted_losore){
            temp.adjusted_losore = 0;
        }
        if(!temp.alm_losore){
            temp.alm_losore = 0;
        }
        if(!temp.special_conditions){
            temp.special_conditions = 0;
        }

        return ({ apartment: this.props.apartment });
    }

    putOnSale(e) {
        e.preventDefault();
        ApartmentService.saleApartment({...this.state.apartment, for_sale: true })
        .catch(function (err) {
            alert("Der opstod en fejl");
            console.log("Error saving", err);
        });
        this.props.salesClick();
    }

    render = () => {
        return <React.Fragment>
            {this.props.sellApartment &&
                <form className="apartmentForm " id="apartmentForm" onSubmit={this.putOnSale.bind(this)}>
                    <div className='col-md-12 slightMarginTopApartments'>

                        <div className="col-md-3 panelRowPadding ">
                            <div>
                                <label>Forbedringer</label>
                            </div>
                            <input className="form-control" name="type" value={this.state.apartment.improvements} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, improvements: event.target.value} })} type="text" />
                        </div>
                        <div className="col-md-3 panelRowPadding ">
                            <div>
                                <label>Tilpassede løsøre</label>
                            </div>
                            <input className="form-control" name="type" value={this.state.apartment.adjusted_losore} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, adjusted_losore: event.target.value} })} type="text" />
                        </div>
                        <div className="col-md-3 panelRowPadding ">
                            <div>
                                <label>Alm. løsøre</label>
                            </div>
                            <input className="form-control" name="type" value={this.state.apartment.alm_losore} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, alm_losore: event.target.value} })} type="text" />
                        </div>
                        <div className="col-md-3 panelRowPadding ">
                            <div>
                                <label>Særlige forhold</label>
                            </div>
                            <input className="form-control" name="type" value={this.state.apartment.special_conditions} onChange={(event) => this.setState({ apartment: { ...this.state.apartment, special_conditions: event.target.value} })} type="text" />
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className="col-md-offset-6 col-md-3 panelRowPadding">
                            <button type="button" className="btn btn-primary" onClick={this.props.salesClick}>Afbryd</button>
                        </div>
                        <div className="col-md-3 panelRowPadding">
                            <button className="btn btn-primary" type="submit">{this.state.apartment.for_sale ? 'Rediger pris' : 'Sæt til salg'}</button>
                        </div>
                    </div>
                </form>}
        </React.Fragment>
    }
}
export default withRouter(SellApartmentComponent);