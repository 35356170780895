import React from 'react'
import HandyManService from '../../../services/HandyManService';
import AuthenticatedComponent from '../../AuthenticatedComponent';
class HandyManEntryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: this.props.edit,
            handyMan: this.props.handyMan
        }
    }

    delete = () => {
        HandyManService.delete(this.props.handyMan);
    }

    edit = () => {
        this.setState({ edit: !this.state.edit })
    }

    createOrUpdate = () => {
        if (!!this.state.handyMan.id && !!this.state.handyMan.type) {
            HandyManService.update(this.state.handyMan);
        } else {
            HandyManService.create({ ...this.state.handyMan, type: this.props.type })
        }
        this.edit();
    }

    render = () => {
        return (
            <React.Fragment>
                {!this.state.edit && <tr key={this.props.handyMan.id + this.props.handyMan.company}>
                    <td>{this.props.handyMan.company}<br />{this.props.handyMan.field}</td>
                    <td>{this.props.handyMan.address}</td>
                    <td>{this.props.handyMan.contact_person}</td>
                    <td>{this.props.handyMan.mobile}</td>
                    <td>{this.props.handyMan.phone}</td>
                    <td>{this.props.handyMan.email}</td>
                    <td>{this.props.handyMan.website}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                        <button className="btn" onClick={this.delete}><span
                            className='bi bi-circle-fill'></span></button>
              &nbsp;
                <button className="btn" onClick={this.edit}><span
                            className='bi bi-document-text'></span></button>
                    </td>
                </tr>}

                {this.state.edit && <tr key={this.state.handyMan.id}>
                    <td colSpan="9">
                        <div className="card">
                            <div className="card-body textAlignLeft">
                                <div className="col-md-12">
                                    <div className="col-md-6 panelRowPadding ">
                                        <div>
                                            <label>Firma</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.handyMan.company} onChange={(event) => this.setState({ handyMan: { ...this.state.handyMan, company: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-6 panelRowPadding ">
                                        <div>
                                            <label>Arbejdsområde</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.handyMan.field} onChange={(event) => this.setState({ handyMan: { ...this.state.handyMan, field: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-6 panelRowPadding ">
                                        <div>
                                            <label>Adresse</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.handyMan.address} onChange={(event) => this.setState({ handyMan: { ...this.state.handyMan, address: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-6 panelRowPadding ">
                                        <div>
                                            <label>Kontaktperson</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.handyMan.contactPerson} onChange={(event) => this.setState({ handyMan: { ...this.state.handyMan, contactPerson: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-6 panelRowPadding ">
                                        <div>
                                            <label>Mobil</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.handyMan.mobile} onChange={(event) => this.setState({ handyMan: { ...this.state.handyMan, mobile: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-6 panelRowPadding ">
                                        <div>
                                            <label>Hoved nr.</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.handyMan.phone} onChange={(event) => this.setState({ handyMan: { ...this.state.handyMan, phone: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-6 panelRowPadding ">
                                        <div>
                                            <label>E-Mail adresse</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.handyMan.email} onChange={(event) => this.setState({ handyMan: { ...this.state.handyMan, email: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-6 panelRowPadding ">
                                        <div>
                                            <label>Hjemmeside</label>
                                        </div>
                                        <input className="form-control" name="type" value={this.state.handyMan.website} onChange={(event) => this.setState({ handyMan: { ...this.state.handyMan, website: event.target.value } })} type="text" />
                                    </div>
                                    <div className="col-md-12 panelRowPadding">
                                        <button className="btn btn-primary pull-right" onClick={this.createOrUpdate}>Gem</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>}
            </React.Fragment>);
    }
}

export default AuthenticatedComponent(HandyManEntryComponent);