import { APARTMENTS_GET, APARTMENT_CREATE, APARTMENT_CHANGE, APARTMENT_DELETE, APARTMENT_CHECK_REPORT, APARTMENT_GET } from '../constants/ApartmentConstants';

export function gotApartments(apartments) {
  return {
    type: APARTMENTS_GET,
    apartments: apartments
  }
}

export function gotApartment(apartment) {
  return {
    type: APARTMENT_GET,
    apartment: apartment
  }
}

export function deleteApartment(apartment) {
  return {
    type: APARTMENT_DELETE,
    apartment: apartment
  }
}

export function checkReport(apartment) {
  return {
    type: APARTMENT_CHECK_REPORT,
    apartment: apartment
  }
}