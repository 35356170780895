
import { LOGOUT_USER } from '../constants/LoginConstants';
import { WAITINGLIST_GET, WAITINGLIST_APPLICATION_GET, WAITINGLIST_APPLICATION_APPROVE, WAITINGLIST_APPLICATION_UPDATE, WAITINGLIST_APPLICATION_DELETE, WAITINGLIST_UPDATE, WAITINGLIST_DELETE } from '../constants/WaitingListConstants';

export function waitingList(state = { _waitingList: [], _applicationWaitingList: [] }, action) {
  switch (action.type) {
    case WAITINGLIST_GET:
      return { ...state, _waitingList: action.waitingList };
    case WAITINGLIST_UPDATE:
      {
        let tempWaitingList = state._waitingList.filter(x => true);
        tempWaitingList.forEach(entry => {
          if(entry.id === action.waitingListEntry.id){
            entry.paid = action.waitingListEntry.paid;
          }
        });
        // let tempWaitingList = state._waitingList.filter(x => x.id !== action.waitingListEntry.id);
        // tempWaitingList.push(action.waitingListEntry);
        return { ...state, _waitingList: tempWaitingList }
      }
    case WAITINGLIST_DELETE:
      return { ...state, _waitingList: state._waitingList.filter(x => x.id !== action.waitingListEntry.id) }

    case WAITINGLIST_APPLICATION_GET:
      return { ...state, _applicationWaitingList: action.applicationWaitingList };
    case WAITINGLIST_APPLICATION_UPDATE:
      {
        let tempApplicationWaitingList = state._applicationWaitingList.filter(x => x.id !== action.waitingListEntry.id);
        tempApplicationWaitingList.push(action.waitingListEntry);
        return { ...state, _applicationWaitingList: tempApplicationWaitingList }
      }
    case WAITINGLIST_APPLICATION_DELETE:
      {
        let tempApplicationWaitingList = state._applicationWaitingList.filter(x => x.id !== action.waitingListEntry.id);
        return { ...state, _applicationWaitingList: tempApplicationWaitingList }
      }
    case WAITINGLIST_APPLICATION_APPROVE:
      {
        let tempApplicationWaitingList = state._applicationWaitingList.filter(x => x.id !== action.waitingListEntry.id);
        let tempAWaitingList = state._waitingList.filter(x => true);
        tempAWaitingList.push(action.waitingListEntry);
        return { ...state, _applicationWaitingList: tempApplicationWaitingList, _waitingList: tempAWaitingList };
      }
      
    case LOGOUT_USER:
      state._waitingList = [];
      return state;
    default:
      return state;
  };
}

