import { LOGIN_USER, LOGOUT_USER } from '../constants/LoginConstants';
import jwt_decode from 'jwt-decode';
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

export function login(state = { _user: null, _jwt: null }, action) {
  
  let jwt = null;
  switch (action.type) {
    case LOGIN_USER:
      jwt = action.jwt;
      let user = jwt_decode(jwt);
      var savedJwt = localStorage.getItem('jwt');
      if (savedJwt !== jwt) {
        localStorage.setItem('jwt', jwt);
      }
      return {...state, _jwt: jwt, _user: user}
    case LOGOUT_USER:
      localStorage.removeItem('jwt');
      return {...state, _jwt:null, _user: null}
    default:
      jwt = localStorage.getItem('jwt');
      if (jwt != null && jwt != '') {
        let user = jwt_decode(jwt);
        return {...state, _jwt: jwt, _user: user}
      }
      return {...state, _jwt:null, _user: null}
  };
}
