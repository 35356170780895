import React from 'react';
import HandyManService from '../../services/HandyManService.js';
import AuthenticatedComponent from '../AuthenticatedComponent';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { HANDYMAN_PRINT_URL } from '../../constants/HandyManConstants.js';
import HandyManEntryComponent from './subcomponents/HandyManEntryComponent.jsx';
import { store } from '../../index.js';
import { addHandyManPrivate, addHandyManPublic } from '../../actions/HandyManActions.js';

class HandyManList extends React.Component {

  constructor() {
    super()
    this.state = {
    };
  }

  componentDidMount() {
    HandyManService.getPrivate();
    HandyManService.getPublic();
  }

  printList = (e) => {
    e.preventDefault();
    window.open(
      HANDYMAN_PRINT_URL,
      '_blank' // <- This is what makes it open in a new window.
    );

  }

  addEmptyHandyMan = (type) => {

    if (type === "private") {
      store.dispatch(addHandyManPrivate({
        company: '',
        field: '',
        address: '',
        contactPerson: '',
        mobile: '',
        phone: '',
        email: '',
        website: '',
        type: type
      }));
    }
    else {
      store.dispatch(addHandyManPublic({
        company: '',
        field: '',
        address: '',
        contactPerson: '',
        mobile: '',
        phone: '',
        email: '',
        website: '',
        type: type
      }));
    }
  }

  render() {
    // TODO: refactor this and make it a dictionary that gets passed
    // var privateEntries = self.getEntries('private', this.props._private,  self.newPrivateClick)
    // var publicEntries = self.getEntries('public', this.props._public, self.newPublicClick)
    var privateEntries = this.props._private.map((handyMan) => {
      if(!!handyMan.id){
        return <HandyManEntryComponent key={handyMan.id} handyMan={handyMan} type="private" edit={false}></HandyManEntryComponent>
      } else {
        return <HandyManEntryComponent key={handyMan.id} handyMan={handyMan} type="private" edit={true}></HandyManEntryComponent>
      }
    })
    var publicEntries = this.props._public.map((handyMan) => {
      if(!!handyMan.id){
        return <HandyManEntryComponent key={handyMan.id} handyMan={handyMan} type="public" edit={false}></HandyManEntryComponent>
      } else {
        return <HandyManEntryComponent key={handyMan.id} handyMan={handyMan} type="public" edit={true}></HandyManEntryComponent>
      }
    })

    privateEntries.push(<tr key={Math.random()}>
      <td colSpan="9">
        <button className="btn btn-primary" onClick={() => this.addEmptyHandyMan("private")}>Tilføj</button>
      </td>
    </tr>)

    publicEntries.push(<tr key={Math.random()}>
      <td colSpan="9">
        <button className="btn btn-primary" onClick={() => this.addEmptyHandyMan("public")}>Tilføj</button>
      </td>
    </tr>)

    return (
      <div className="container standardBackground">
        <div className="row">
          <div className="col-md">
            <button className="btn btn-primary" onClick={this.printList}>Udskriv</button>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md">
            <h3>Privat</h3>
            {this.props._privateLoading &&
              <div id="loading">Loading...</div>}
            {!this.props._privateLoading &&
              <React.Fragment>
                <table className="table table-md-responsive table-hover handymanList">
                  <thead>
                    <tr >
                      <th style={{ textAlign: "left" }}>Firma</th>
                      <th style={{ textAlign: "left" }}>Adresse</th>
                      <th style={{ textAlign: "left" }}>Kontaktperson</th>
                      <th style={{ textAlign: "left" }}>Mobil</th>
                      <th style={{ textAlign: "left" }}>Hoved nr.</th>
                      <th style={{ textAlign: "left" }}>E-Mail adresse</th>
                      <th style={{ textAlign: "left" }}>Hjemmeside</th><th></th>
                    </tr>
                  </thead>
                  <tbody>{privateEntries}</tbody>
                </table>
              </React.Fragment>}
            <h3>Offentlig</h3>
            {this.props._publicLoading &&
              <div id="loading">Loading...</div>}
            {!this.props._publicLoading &&
              <React.Fragment>
                <table className="table table-md-responsive table-hover handymanList">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>Firma</th>
                      <th style={{ textAlign: "left" }}>Adresse</th>
                      <th style={{ textAlign: "left" }}>Kontaktperson</th>
                      <th style={{ textAlign: "left" }}>Mobil</th>
                      <th style={{ textAlign: "left" }}>Hoved nr.</th>
                      <th style={{ textAlign: "left" }}>E-Mail adresse</th>
                      <th style={{ textAlign: "left" }}>Hjemmeside</th><th></th>
                    </tr>
                  </thead>
                  <tbody>{publicEntries}</tbody>
                </table>
              </React.Fragment>}
            <br />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { _public, _private, _privateLoading, _publicLoading } = state.handymen;
  return { _public, _private, _privateLoading, _publicLoading };
}

export default AuthenticatedComponent(withRouter(connect(mapStateToProps)(HandyManList)))