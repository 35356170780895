/**
 * Created by nh on 12/20/15.
 */
import {BASE_URL} from '../constants/SystemConstants';


export const NEWS_PUBLIC = BASE_URL + 'news/public';
export const NEWS_ADMIN = BASE_URL + 'news/admin';
export const PUBLIC_GET = 'PUBLIC_GET';
export const ADMIN_GET = 'ADMIN_GET';
export const ADMIN_UPDATE = 'ADMIN_UPDATE';
export const PUBLIC_UPDATE = 'PUBLIC_UPDATE';
export const PUBLIC_DELETE = 'PUBLIC_DELETE';
export const ADMIN_DELETE = 'ADMIN_DELETE';