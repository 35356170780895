import { RATE_GET, RATE_UPDATE, BOARD_CREATE, BOARD_CHANGE, BOARD_DELETE, BOARD_GET, MEMBER_UPDATE, MEMBER_GET } from '../constants/RateConstants';

export function update(rate) {
  return {
    type: RATE_UPDATE,
    rate: rate.rate
  }
}

export function got(rate) {
  return {
    type: RATE_GET,
    rate: rate
  }
}
export function changeBoard(board) {
  return {
    type: BOARD_CHANGE,
    board: board
  }
}

export function createBoard(board) {
  return {
    type: BOARD_CREATE,
    board: board
  }
}

export function deleteBoard(board) {
  return {
    type: BOARD_DELETE,
    board: board
  }
}

export function gotBoard(board) {
  return {
    type: BOARD_GET,
    board: board
  }
}


export function gotMember(member) {
  return {
    type: MEMBER_GET,
    member: member
  }
}


export function updateMember(member) {
  return {
    type: MEMBER_UPDATE,
    member: member
  }
}