import React from 'react';
import AuthenticatedComponent from './AuthenticatedComponent'
import GeneralAssemblyConstants from '../constants/GeneralAssemblyConstants.js';
import { withRouter } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import {  formatDateHyphensReverse, formatDateTimeReverse } from '../helpers/dateFormatter';

class GeneralAssembly extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nonNormalGA: false,
      date: new Date(),
      fuldmagtdate: new Date(),
      place: ''
    };
  }

  generateReport() {
    if(this.state.place !== ''){
      window.alert("Dette kan tage op til 2 minutter");
      window.open(GeneralAssemblyConstants.GENERAL_ASSEMBLY_URL + "/" + formatDateTimeReverse(this.state.date) + "/" + this.state.nonNormalGA + "/" + formatDateHyphensReverse(this.state.fuldmagtdate) + "/" + this.state.place + "/generalforsamling", '_blank');
    } else {
      window.alert('udfyld venligst sted for generalforsamling')
    }
  }

  render() {
    var self = this;

    return (
      <div className="container ">
        <div className="row">
          <div className="standardBackground col-md-4 offset-md-4 standardBackground">
            <div className="form-group">
              <label>Dato og klokkeslæt for generalforsamling</label>
              <DatePicker
                dateFormat="dd/MM/yyyy HH:mm"
                selected={this.state.date}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="tid"
                onChange={date => { this.setState({ date: date }) }}
                className="form-control"
              />
              {/* <input type="text" value={this.state.date} onChange={(event) => this.setState({ date: event.target.value })} className="form-control" id="phone" /> */}
            </div>
            <div className="form-group">
              <label>Sted for generalforsamling</label>
              <input type="text" value={this.state.place} onChange={(event) => this.setState({ place: event.target.value })} className="form-control" id="phone" />
            </div>
            <div className="form-group">
              <label>Fuldmagtsblanket dato</label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.fuldmagtdate}
                onChange={date => { this.setState({ fuldmagtdate: date }) }}
                className="form-control"
              />
              {/* <input type="text" value={this.state.fuldmagtdate} onChange={(event) => this.setState({ fuldmagtdate: event.target.value })} className="form-control" id="phone" /> */}
            </div>
            <div className="form-group marginBottom1">
              <label className="col-md-12" >Er det en ekstraordinær generalforsamling?</label>
              <input className="col-md-12" type="checkbox" checked={this.state.nonNormalGA} onChange={(event) => this.setState({ nonNormalGA: event.target.checked })} />
            </div>
            <div className="form-group">
              <button className="btn btn-primary" onClick={self.generateReport.bind(this)}>Generer dokumenter</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthenticatedComponent(withRouter(GeneralAssembly))