import React from 'react';
import AuthenticatedComponent from './AuthenticatedComponent';
import NewsService from '../services/NewsService.js';
import ReactQuill from 'react-quill';
import { withRouter } from 'react-router-dom';

class NewsEditorComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      letter: ''
    }
  }

  backClick() {
    this.props.history.push('/');
  }

  sendAdmin(e) {
    e.preventDefault();
    NewsService.adminNewsPost({
      content: this.state.letter
    });
    NewsService.adminNewsGet();
    this.props.history.push('/hjem')
  }

  sendPublic(e) {
    e.preventDefault();
    NewsService.publicNewsPost({
      content: this.state.letter
    });
    NewsService.publicNewsGet();
    this.props.history.push('/hjem')
  }

  sendBoth(e) {
    e.preventDefault();
    NewsService.adminNewsPost({
      content: this.state.letter
    });
    NewsService.adminNewsGet();
    NewsService.publicNewsPost({
      content: this.state.letter
    });
    NewsService.publicNewsGet();
    this.props.history.push('/hjem')
  }

  onTextChange(value) {
    this.setState({ letter: value });

  }

  render() {
    var self = this;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div id="emailEdit">
                  <ReactQuill theme="snow" value={this.state.letter} onChange={self.onTextChange.bind(self)} />
                  <button className="btn btn-primary" onClick={self.backClick.bind(self)}>Afbryd</button>
                  <button className="btn btn-primary ml-2" onClick={self.sendPublic.bind(self)}>Publicer offentligt</button>
                  <button className="btn btn-primary ml-2" onClick={self.sendAdmin.bind(self)}>Publicer til admins</button>
                  <button className="btn btn-primary ml-2" onClick={self.sendBoth.bind(self)}>Publicer til begge</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AuthenticatedComponent(withRouter(NewsEditorComponent));