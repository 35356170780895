import React from 'react';
import { withRouter } from 'react-router-dom';

class ApartmentTenantComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState()
    }

    getInitialState = () => {
        return {
            edit: false,
            apartmentTenant: this.props.apartmentTenant
        };
    }    
    
    splitNumber(number) {
        if (!!number) {
            var newNumber = number.replace(/\D/g, '');
            return newNumber.replace(/(.{2})/g, '$1 ').trim();
        }
        return '';
    }

    render = () => {
        var phone = this.splitNumber(this.props.apartmentTenant.tenant.phone);
        
        return <tbody>
        <tr className={this.props.warning}>
            <td onClick={() => this.props.toggleShowInfo()} >{this.props.apartmentTenant && this.props.apartmentTenant.tenant.firstname} {this.props.apartmentTenant && this.props.apartmentTenant.tenant.lastname}</td>
            <td onClick={() => this.props.toggleShowInfo()} >{this.props.apartmentTenant && phone}</td>

            <td >{this.props.apartmentTenant != null ? <a href={"mailto:" + (this.props.apartmentTenant.tenant.email != null ? this.props.apartmentTenant.tenant.email : "no@email.com") + "?Subject=Kontakt"} target="_top">{this.props.apartmentTenant && this.props.apartmentTenant.tenant.email}</a> : ""}</td>
        </tr>
    </tbody>
    }
}
export default withRouter(ApartmentTenantComponent);