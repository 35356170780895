import { MOVERS_GET, MOVERS_CREATE, MOVERS_UPDATE, MOVERS_DELETE } from '../constants/MoverConstants';
import { LOGOUT_USER } from '../constants/LoginConstants';

export function mover(state = { _movers: []}, action) {
  switch (action.type) {
    case MOVERS_GET:
      let tempMovers = state._movers.filter(x => true);
      tempMovers.sort((a, b) => a.id - b.id);
      return {...state, _movers: action.movers}
    case MOVERS_UPDATE:
      {
        let tempMovers = state._movers.filter(x => x.id !== action.mover.id)
        tempMovers.push(action.mover);
        tempMovers.sort((a, b) => a.id - b.id);
        return {...state, _movers: tempMovers}
      }
    case MOVERS_CREATE:
      //Should implement it being placed after date
      {
        let tempMovers = state._movers.filter(x => true);
        tempMovers.push(action.mover);
        tempMovers.sort((a, b) => a.id - b.id);
        return {...state, _movers: tempMovers}
      }
    case MOVERS_DELETE:
      return {...state, _movers: state._movers.filter(x => x.id !== action.mover.id)};
    case LOGOUT_USER:
      state._movers = [];
      return state;
    default:
      return state;
  }
}
