import { NEWS_PUBLIC, NEWS_ADMIN, PUBLIC_GET, ADMIN_GET, ADMIN_UPDATE, PUBLIC_UPDATE, PUBLIC_DELETE, ADMIN_DELETE } from '../constants/NewsConstants.js';


export function publicUpdate(news) {
  return {
    type: PUBLIC_UPDATE,
    news: news
  }
}

export function adminUpdate(news) {
  return {
    type: ADMIN_UPDATE,
    adminNews: news
  }
}

export function gotPublicNews(news) {
  return {
    type: PUBLIC_GET,
    news: news
  }
}
export function gotAdminNews(news) {
  return {
    type: ADMIN_GET,
    adminNews: news
  }
}

export function deletePublicNews(news) {
  return {
    type: PUBLIC_DELETE,
    news: news
  }
}

export function deleteAdminNews(news) {
  return {
    type: ADMIN_DELETE,
    adminNews: news
  }
}