import { NEWS_PUBLIC, NEWS_ADMIN, PUBLIC_GET, ADMIN_GET, PUBLIC_UPDATE, ADMIN_UPDATE, PUBLIC_DELETE, ADMIN_DELETE } from '../constants/NewsConstants';

export function news(state = { _publicNews: [], _adminNews: [] }, action) {
  switch (action.type) {
    case ADMIN_GET:
      return { ...state, _adminNews: action.adminNews };
    case PUBLIC_GET:
      return { ...state, _publicNews: action.news };
    case PUBLIC_UPDATE:
      let news = state._publicNews.filter(x => x.id !== action.news.id);
      news.unshift(action.news);
      return { ...state, _publicNews: news };
    case ADMIN_UPDATE:
      let adminNews = state._adminNews.filter(x => x.id !== action.adminNews.id);
      adminNews.unshift(action.adminNews);
      return { ...state, _adminNews: adminNews };
    case PUBLIC_DELETE:
      return { ...state, _publicNews: state._publicNews.filter(x => x.id !== action.news.id) }
    case ADMIN_DELETE:
      return { ...state, _adminNews: state._adminNews.filter(x => x.id !== action.adminNews.id) }
    default:
      return state;
  };
}