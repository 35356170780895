import React from 'react';
import MoverService from '../../../services/MoverService';
import DatePicker from 'react-date-picker';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { formatDate, formatStringDate, formatStringDateReverse } from '../../../helpers/dateFormatter'
import { parseISO } from 'date-fns';
import AuthenticatedComponent from '../../AuthenticatedComponent';
class CreateMoverComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            addNew: false,
            mover: this.props.mover,
            number: null
        };
    }

    toggleAddNew = () => {
        this.setState({ addNew: !this.state.addNew });
    }

    save = () => {
        var newApartment = this.props._apartments.filter(x => x.number === parseInt(this.state.number));

        MoverService.create({ ...this.state.mover, apartment_id: newApartment[0].id });

        this.toggleAddNew();
    }

    render = () => {
        return (
            <React.Fragment>{!this.state.addNew ?
                <tr>
                    <td colSpan="9">
                        <button className="btn btn-primary pull-right" onClick={this.toggleAddNew}>Tilføj</button>
                    </td>
                </tr>
                :
                <tr>
                    <td colSpan="9">
                        <div className="panel panel-default">
                            <div className="panel-body textAlignLeft">
                                <div className="col-md-12">
                                    <form className="apartmentForm" id="apartmentForm" onSubmit={this.save.bind(this)}>
                                        <div className="row">
                                            <div className="col-md-4 panelRowPadding ">
                                                <div>
                                                    <label>Overtagelsesdato</label>
                                                </div>
                                                <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        value={this.state.mover.takeover_date ? new Date(parseISO(this.state.mover.takeover_date)) : null}
                                                        onChange={date => { this.setState({ mover: { ...this.state.mover, takeover_date: date.toISOString() } }) }}
                                                        className="form-control"
                                                    />
                                            </div>
                                            <div className="col-md-4 panelRowPadding ">
                                                <div>
                                                    <label>Andels nr.</label>
                                                </div>
                                                <input className="form-control" name="type" value={this.state.number} onChange={(event) => this.setState({ number: event.target.value })} type="text" />
                                            </div>
                                            <div className="col-md-4 panelRowPadding ">
                                                <div>
                                                    <label>Sælgers navn</label>
                                                </div>
                                                <input className="form-control" name="type" value={this.state.mover.seller_name} onChange={(event) => this.setState({ mover: { ...this.state.mover, seller_name: event.target.value } })} type="text" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 panelRowPadding ">
                                                <div>
                                                    <label>Sælgers addresse</label>
                                                </div>
                                                <input className="form-control" name="type" value={this.state.mover.seller_address} onChange={(event) => this.setState({ mover: { ...this.state.mover, seller_address: event.target.value } })} type="text" />
                                            </div>
                                            <div className="col-md-4 panelRowPadding ">
                                                <div>
                                                    <label>Sælgers by</label>
                                                </div>
                                                <input className="form-control" name="type" value={this.state.mover.seller_suburb} onChange={(event) => this.setState({ mover: { ...this.state.mover, seller_suburb: event.target.value } })} type="text" />
                                            </div>
                                            <div className="col-md-4 panelRowPadding ">
                                                <div>
                                                    <label>Sælgers postnummer</label>
                                                </div>
                                                <input className="form-control" name="type" value={this.state.mover.seller_postcode} onChange={(event) => this.setState({ mover: { ...this.state.mover, seller_postcode: event.target.value } })} type="text" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 panelRowPadding ">
                                                <div>
                                                    <label>Sælgers e-mail</label>
                                                </div>
                                                <input className="form-control" name="type" value={this.state.mover.seller_email} onChange={(event) => this.setState({ mover: { ...this.state.mover, seller_email: event.target.value } })} type="text" />
                                            </div>
                                            <div className="col-md-4 panelRowPadding ">
                                                <div>
                                                    <label>Købers navn</label>
                                                </div>
                                                <input className="form-control" name="type" value={this.state.mover.buyer_name} onChange={(event) => this.setState({ mover: { ...this.state.mover, buyer_name: event.target.value } })} type="text" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md">
                                                <button className="btn btn-primary" type="submit" >Opret</button>
                                                <button className="btn btn-primary ml-2" onClick={this.toggleAddNew}>Afbryd</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { _apartments } = state.apartments;
    return { _apartments };
}

export default AuthenticatedComponent(withRouter(connect(mapStateToProps)(CreateMoverComponent)))