import request from 'reqwest';
import when from 'when';
import { RATE_URL, BOARD_URL, MEMBER_URL, SETTINGS_URL } from '../constants/RateConstants';
import { update, got, gotBoard, gotMember, updateMember, changeBoard, createBoard, deleteBoard } from '../actions/RateActions';
import AppDispatcher from '../dispatchers/AppDispatcher.js';
import AuthService from '../services/AuthService'
import { store } from '../index';

class RateService {
  ratePut(rate) {
    return request({
      url: RATE_URL,
      method: 'put',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(rate)
    })
      .then(function (response) {
        store.dispatch(update(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  ratesGet() {
    request({
      url: RATE_URL,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
      .then(function (response) {
        store.dispatch(got(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  boardGet() {
    request({
      url: BOARD_URL,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
      .then(function (response) {

        store.dispatch(gotBoard(response));
      });
  }

  memberGet(t) {
    request({
      url: MEMBER_URL + "/" + t,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
      .then(function (response) {
        store.dispatch(gotMember(response));
      });
  }

  memberPut(member) {
    return request({
      url: MEMBER_URL,
      method: 'PUT',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(member)
    })
      .then(function (response) {
        store.dispatch(updateMember(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  boardMemberPut(boardmember) {
    return request({
      url: BOARD_URL,
      method: 'put',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(boardmember.boardmember)
    })
      .then(function (response) {
        store.dispatch(changeBoard(response));
      });
  }

  boardMemberPost(boardmember) {
    return request({
      url: BOARD_URL,
      method: 'post',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(boardmember.name)
    })
      .then(function (response) {
        store.dispatch(createBoard(response));
      });
  }

  boardMemberDelete(boardmember) {
    return request({
      url: BOARD_URL,
      method: 'delete',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(boardmember)
    }).then(function (response) {
      store.dispatch(deleteBoard(response));
    });
  }
}

export default new RateService();