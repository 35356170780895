import React from 'react';
import { withRouter } from 'react-router-dom';
import { formatStringDate } from '../../../helpers/dateFormatter'
import { isThisHour } from 'date-fns';
import ApartmentTenantService from '../../../services/ApartmentTenantService';
import EditApartmentTenantComponent from './EditApartmentTenantComponent';

class ApartmentTenantMoreInfoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState()
    }

    getInitialState = () => {
        return {
            edit: false,
            apartmentTenant: this.props.apartmentTenant
        };
    }

    splitNumber(number) {
        if (!!number) {
            var newNumber = number.replace(/\D/g, '');
            return newNumber.replace(/(.{2})/g, '$1 ').trim();
        }
        return '';
    }

    toggleEdit = () => {
        this.setState({ edit: !this.state.edit })
    }

    deleteTenant = () => {
        if(window.confirm("Vil du slette " + this.props.apartmentTenant.tenant.firstname + " " + this.props.apartmentTenant.tenant.lastname+ " fra denne bolig?")){
            ApartmentTenantService.deleteApartmentTenant(this.props.apartmentTenant, this.props.apartment);
        }
    }

    render = () => {
        var phone = this.splitNumber(this.props.apartmentTenant.tenant.phone);

        return <React.Fragment>{!this.state.edit ? <div className={"row mb-2 " + this.props.warning}>
            <div className="col">
                <div className="row">
                    <div className="col">
                        <label><b>Navn</b></label><br />
                        {this.props.apartmentTenant && this.props.apartmentTenant.tenant.firstname} {this.props.apartmentTenant && this.props.apartmentTenant.tenant.lastname}
                    </div>
                    <div className="col">
                        <label><b>Telefon</b></label><br />
                        {this.props.apartmentTenant && phone}
                    </div>
                    <div className="col">
                        <label><b>Email</b></label><br />
                        {this.props.apartmentTenant != null ? <a href={"mailto:" + (this.props.apartmentTenant.tenant.email != null ? this.props.apartmentTenant.tenant.email : "no@email.com") + "?Subject=Kontakt"} target="_top">{this.props.apartmentTenant && this.props.apartmentTenant.tenant.email}</a> : ""}
                    </div>
                    <div className="col">
                        <label><b>Indflytningdato</b></label><br />
                        {this.props.apartmentTenant && formatStringDate(this.props.apartmentTenant.seniority_date)}
                    </div>
                    <div className="col">
                        <label><b>Seneste flytning</b></label><br />
                        {this.props.apartmentTenant && formatStringDate(this.props.apartmentTenant.seniority_date2)}
                    </div>
                </div>
                <div className="row">
                    <div class="col-2">
                        <button className="btn btn-primary" onClick={() => this.toggleEdit()}>Rediger beboer</button>
                    </div>
                    <div class="col-2">
                        <button className="btn btn-primary" onClick={() => this.deleteTenant()}>Slet beboer</button>
                    </div>
                </div>
            </div>
        </div> : <EditApartmentTenantComponent apartment={this.props.apartment} apartmentTenant={this.props.apartmentTenant} toggleEdit={this.toggleEdit}></EditApartmentTenantComponent>}
        </React.Fragment>
    }
}
export default withRouter(ApartmentTenantMoreInfoComponent);