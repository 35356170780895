import React from 'react';

export class WaitingListApplyError extends React.Component {
    constructor(props) {
        super(props);
    }

    
    render() {
        return (
            <div className="container">
                <div className="row vh-100 d-flex">
                    <div className="col-md-8 offset-md-2 login center-block align-self-center">
                        <div className="standardBackground">
                            <h2>Der opstod en fejl under betalingen.</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}