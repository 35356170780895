import React from 'react';
import Auth from '../services/AuthService'
import WaitingListApply from './WaitingList/WaitingListApply'
import AuthenticatedApp from '../components/AuthenticatedApp'
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import { withRouter } from 'react-router-dom'
import { isAuthenticated } from '../helpers/isAuthenticated';

class Login extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      user: '',
      password: '',
      loggedIn: isAuthenticated()
    };
  }

  login(e) {
    e.preventDefault();
    Auth.login(this.state.user, this.state.password)
      .then(() => {
        this.setState({ user: '', password: '', loggedIn: true });
      })
      .catch(function (err) {
        alert("There's an error logging in");
        console.log("Error logging in", err);
      });
  }

  render() {

    return (
      <div className="container">
        <div className="row">
          <div className="col-md mx-auto login center-block text-center">
            <div className="card offset-md-4 col-md-4">
              <div className="card-body">
                <h2>Log på</h2>
                {this.state.loggedIn && <div style={{ textAlign: "center" }}><Redirect to={"/hjem"} />Du er logget ind.</div>}
                {!this.state.loggedIn && <form>
                  <div className="form-group">
                    <input type="text" value={this.state.user} onChange={(event) => this.setState({ user: event.target.value })} className="form-control" id="username" placeholder="Brugernavn" />
                  </div>
                  <div className="form-group">
                    <input type="password" value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })} className="form-control" id="password" ref="password" placeholder="Adgangskode" />
                  </div>
                  <div className="form-buttons">
                    <button type="submit" className="btn btn-primary" onClick={this.login.bind(this)}>Log på</button>
                  </div>
                </form>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { _user, _jwt } = state.login
  return { _user, _jwt };
}

export default withRouter(connect(mapStateToProps)(Login))