import {BASE_URL} from '../constants/SystemConstants';

export const HANDYMAN_URL= BASE_URL + 'handyman';
export const HANDYMAN_PRIVATE_URL= BASE_URL + 'handyman/private';
export const HANDYMAN_PUBLIC_URL= BASE_URL + 'handyman/public';
export const HANDYMAN_PRINT_URL= BASE_URL + 'handyman/print';
export const HANDYMAN_PRIVATE_GET= 'HANDYMAN_PRIVATE_GET';
export const HANDYMAN_PUBLIC_GET= 'HANDYMAN_PUBLIC_GET';
export const HANDYMAN_PRIVATE_UPDATE= 'HANDYMAN_PRIVATE_UPDATE';
export const HANDYMAN_PUBLIC_UPDATE= 'HANDYMAN_PUBLIC_UPDATE';
export const HANDYMAN_RM= 'HANDYMAN_RM';
export const HANDYMAN_PRINT= 'HANDYMAN_PRINT';
export const HANDYMAN_ADD_PRIVATE = 'HANDYMAN_UPDATE_NEW_PRIVATE';
export const HANDYMAN_ADD_PUBLIC = 'HANDYMAN_UPDATE_NEW_PUBLIC';