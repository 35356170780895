import { format, parseISO } from "date-fns";

function cleanFormat(date) {
    if(date.indexOf('T'))
    {  
        return date.split("T")[0];
    } else {
        return date;
    }
}

export function formatStringDate(date) {
    if(!!date) {
        return format(parseISO(date), 'dd/MM/yyyy');
    } else {
        return null;
    }
}

export function formatDate(date) {
    if(!!date) {
        return format(date, 'dd/MM/yyyy');
    } else {
        return null;
    }
}

export function formatDateReverse(date) {
    if(!!date) {
        return format(date, 'yyyy/MM/dd');
    } else {
        return null;
    }
}

export function formatStringDateReverse(date) {
    if(!!date) {
        return format(parseISO(date), 'yyyy/MM/dd');
    } else {
        return null;
    }
}

export function formatDateHyphens(date) {
    if(!!date) {
        return format(date, 'dd-MM-yyyy');
    } else {
        return null;
    }
}

export function formatDateHyphensReverse(date) {
    if(!!date) {
        return format(date, 'yyyy-MM-dd');
    } else {
        return null;
    }
}

export function formatDateTime(date) {
    if(!!date) {
        return format(date, 'dd-MM-yyyy%20HH:mm');
    } else {
        return null;
    }
}

export function formatDateTimeReverse(date) {
    if(!!date) {
        return format(date, 'yyyy-MM-dd%20HH:mm');
    } else {
        return null;
    }
}