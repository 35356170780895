import React from 'react';
import HandyManService from '../services/HandyManService.js';
import { connect } from 'react-redux';

class HandyManListView extends React.Component {


  componentDidMount() {
    this.requestPublic();
  }

  _onChange() {
    this.setState({
      public: this.getPublicState()
    });
  }

  getPublicState() {
    return this.props._public;
  }

  requestPublic() {
    HandyManService.getPublic();
  }

  render() {
    var publicEntries = []
    console.log(this.props)
    if (this.props._public) {
      this.props._public.forEach(function (entry) {
        publicEntries.push(<tr>
          <td>{entry.company}</td>
          <td>{entry.field}</td>
          <td>{entry.address}</td>
          <td>{entry.contact_person}</td>
          <td>{entry.mobile}</td>
          <td>{entry.phone}</td>
          <td>{entry.email}</td>
          <td>{entry.website}</td>
        </tr>);
      });
    }

    return (
      <div className="container">
        <div className="row">
          <div className="standardBackground handyman col-md-10 offset-md-1">
            <h2>Håndværkerliste</h2>
            <div>
              <table className="table handymanList">
                <thead>
                  <tr>
                    <th>Firma</th><th>Adresse</th><th>Kontaktperson</th><th>Mobil</th><th>Hoved nr.</th><th>E-Mail adresse</th><th>Hjemmeside</th><th></th>
                  </tr>
                </thead>
                <tbody>{publicEntries}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { _public } = state.handymen
  return { _public };
}

export default connect(mapStateToProps)(HandyManListView)
