import { HANDYMAN_PRIVATE_UPDATE, HANDYMAN_PUBLIC_UPDATE, HANDYMAN_PRIVATE_GET, HANDYMAN_PUBLIC_GET, HANDYMAN_RM, HANDYMAN_PRINT, HANDYMAN_ADD_PRIVATE, HANDYMAN_ADD_PUBLIC } from '../constants/HandyManConstants';

export function handymanPrivatePut(handyMan) {
  return {
    type: HANDYMAN_PRIVATE_UPDATE,
    handyMan: handyMan
  }
}
export function handymanPublicPut(handyMan) {
  return {
    type: HANDYMAN_PUBLIC_UPDATE,
    handyMan: handyMan
  }
}
export function handymanPrivateGet(handyManList) {
  return {
    type: HANDYMAN_PRIVATE_GET,
    handyManList: handyManList
  }
}
export function handymanPublicGet(handyManList) {
  return {
    type: HANDYMAN_PUBLIC_GET,
    handyManList: handyManList
  }
}
export function handymanDelete(handyMan) {
  return {
    type: HANDYMAN_RM,
    handyMan: handyMan
  }
}
export function handymanGotPdf(pdf) {
  return {
    type: HANDYMAN_PRINT,
    pdf: pdf
  }
}
export function addHandyManPrivate(handyMan) {
  return {
    type: HANDYMAN_ADD_PRIVATE,
    handyMan: handyMan
  }
}
export function addHandyManPublic(handyMan) {
  return {
    type: HANDYMAN_ADD_PUBLIC,
    handyMan: handyMan
  }
}