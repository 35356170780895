import React from "react";
import AuthenticatedComponent from "./AuthenticatedComponent";
import ApartmentService from "../services/ApartmentService.js";
import EmailService from "../services/EmailService.js";
import ReactQuill from "react-quill";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import RateService from "../services/RateService";

const RecipientType = {
  WaitinglistRecipients: "waitinglistrecipients",
  CoopShareHolders: "coopshareholders"
}

class LetterEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    var checked = [];
    var recipientType = RecipientType.WaitinglistRecipients;
    if (this.props.query.get("apartments")) {
      var apartments = this.props.query.get("apartments");
      checked = apartments.split(",");
    }

    return {
      checked: checked,
      letter: null,
      waitingListLetters: {
        B: true,
        D: true,
        E: true,
      },
      recipientType: recipientType
    };
  };

  componentDidMount() {
    if (this.props._apartments.length === 0) {
      ApartmentService.getApartments();
    }
    if (!this.props._rate) {
      RateService.ratesGet();
    }
  }

  getApartmentsState() {
    return this.props._apartments;
  }

  backClick() {
    this.props.history.push("/lejligheder");
  }

  sendClick(e) {
    e.preventDefault();

    if(this.state.recipientType==RecipientType.CoopShareHolders) {
      EmailService.sendCoopOwnerEmail({
        subject: "Besked fra A/B Vanløsegaard",
        content: this.state.letter
      });
    } else
    {
    var waitingListLetters = [];

    if (this.state.waitingListLetters.B) {
      waitingListLetters.push("B");
    }

    if (this.state.waitingListLetters.D) {
      waitingListLetters.push("D");
    }

    if (this.state.waitingListLetters.E) {
      waitingListLetters.push("E");
    }

    EmailService.sendWaitingListEmail({
      subject: "Lejlighed(er) til salg",
      content: this.state.letter,
      waitinglistletters: waitingListLetters,
    });
  }
    this.props.history.push("/lejligheder");
  }


  footer() {
    return '<div><span>Med venlig hilsen</span></div>'+
  '<div>Bestyrelsen</div>'+
  '<div>A/B Vanl&oslash;segaard</div><div><br />'+
  '</div><div>-------------------------------------</div>'+
  '<div><span>Jernbane All&eacute; 92, st.th. 2720 Vanl&oslash;se</span></div>'+
  '<div>www.vanlosegaard.dk</div><div>Telefon 38 74 48 52</div><div>Cvr nr. 43076019</div><div>Matr. nr. 2495</div><div>BBR nr. 285529</div>';
  };

  coopOwnerLetter(){
    return this.footer();
  }

  waitingListLetter(apartments, checked, recipientType) {
    var self = this;

    var addressesDiv = "";
    apartments.forEach(function (apartment) {
      if (checked.includes(apartment.id.toString()))
        addressesDiv +=
          "<div>" +
          apartment.address +
          "   Kl. XX.XX - XX.XX   <br>Maksimalpris: kr. " +
          apartment.price +
          "<br>" +
          apartment.type +
          " " +
          apartment.sqm +
          "Kvm.<br>Andelsværdi:" +
          apartment.andelskroner *
          parseFloat(self.props._rate.replace(",", ".")) +
          " kr.<br>Forbedringer:" +
          apartment.improvements +
          " kr.<br>Alm løsøre:" +
          apartment.alm_losore +
          " kr.<br>Tilpassede løsøre: " +
          apartment.adjusted_losore +
          " kr.<br>Fradrag: " +
          apartment.special_conditions +
          " kr.<br>Månedlig boligafgift: " +
          apartment.apartmentFee +
          " kr.<br><br></div>";
    });

    return (
      '<div style="font-family: sans-serif;"><div><span style="font-size: 18px;"><b>Andelsboligforeningen Vanl&oslash;segaard</b></span></div><div><br /></div><div><span><b>Vedr.: Salg aflejligheder</b></span></div><div><br /></div><div>XX. XXXXXX 20XX fremvises f&oslash;lgende lejlighed er til salg i A/B Vanl&oslash;segaard</div><div><br /></div><div><br /></div>' +
      addressesDiv +
      '<div><br /></div><div><br /></div><div><span><i>NB! Prisen er maksimalprisen inklusive fradrag for s&aelig;rlige forhold.</i></span></div><div><br /></div><div>I kan til fremvisningen f&aring; oplyst, hvor I st&aring;r p&aring; ventelisten til netop denne lejlighed.</div><div><br /></div><div>Den oplyste andelsv&aelig;rdi tager ikke hensyn til forbedringer og fradrag, og der vil s&aring;ledes v&aelig;re forskel til den reelle andelsv&aelig;rdi. Andelsv&aelig;rdien er oplyst idet vurderingsrapporterne ikke er modtaget endnu. <b>Den g&aelig;ldende maksimale overdragelsessum kan oplyses ved fremvisningen.</b></div><div><br /></div>'+
      this.footer() +'</div>'
    );
  }

  onTextChange(value) {
    this.setState({ letter: value });
  }

  waitingListSelector() {

    return (<div className="col-4">
      <div className="form-check form-check-inline">
        <input
          className="form-check-input mb-0"
          type="checkbox"
          id="inlineCheckbox1"
          checked={this.state.waitingListLetters.B}
          onChange={() =>
            this.setState({
              waitingListLetters: {
                ...this.state.waitingListLetters,
                B: !this.state.waitingListLetters.B,
              }
            })
          }
        />
        <label className="form-check-label" for="inlineCheckbox1">
          B
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input mb-0"
          type="checkbox"
          id="inlineCheckbox2"
          checked={this.state.waitingListLetters.D}
          onChange={() =>
            this.setState({
              waitingListLetters: {
                ...this.state.waitingListLetters,
                D: !this.state.waitingListLetters.D,
              },
            })
          }
        />
        <label className="form-check-label" for="inlineCheckbox2">
          D
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input mb-0"
          type="checkbox"
          id="inlineCheckbox3"
          checked={this.state.waitingListLetters.E}
          onChange={() =>
            this.setState({
              waitingListLetters: {
                ...this.state.waitingListLetters,
                E: !this.state.waitingListLetters.E,
              },
            })
          }
        />
        <label className="form-check-label" for="inlineCheckbox3">
          E
        </label>
      </div>
    </div>)
  }

  render() {
    var self = this;

    if (this.state.recipientType == RecipientType.WaitinglistRecipients &&
      this.state.letter === null &&
      this.props._rate &&
      this.props._apartments.length > 0) {
      this.setState({
        letter: this.waitingListLetter(this.props._apartments, this.state.checked, this.state.recipientType)
      });
    }

    return (
      <div className="container standardBackground">
        <div className="row my-3">
          <form className="form-inline mt-1 col-12">

            <div className="col-4">
              <h4>Hvem skal modtage beskeden?</h4>
            </div>

            <div className="col-4 ">
              <div className="form-check ">
                <input
                  className="form-check-input mb-0"
                  type="radio"
                  id="inlineRadiobox1"
                  checked={this.state.recipientType == RecipientType.WaitinglistRecipients ? true : false}
                  onChange={() =>
                    this.setState({
                      recipientType: RecipientType.WaitinglistRecipients
                    }
                    )
                  }
                />
                <label className="form-check-label" for="inlineRadiobox2">
                  Folk på venteliste
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input mb-0"
                  type="radio"
                  id="inlineRadiobox2"
                  checked={this.state.recipientType == RecipientType.CoopShareHolders ? true : false}
                  onChange={() =>
                    this.setState({
                      recipientType: RecipientType.CoopShareHolders,
                      letter: this.coopOwnerLetter()
                    }
                    )
                  }
                />
                <label className="form-check-label" for="inlineCheckbox1">
                  Alle andelshavere
                </label>
              </div>
            </div>
            {this.state.recipientType == RecipientType.WaitinglistRecipients &&
              this.waitingListSelector()}
          </form>

        </div>
        <div className="row">
          {this.state.loading && <div>Loading...</div>}
          {!this.state.loading && (
            <div id="emailEdit" className="col-md">
              <ReactQuill
                theme="snow"
                value={this.state.letter}
                onChange={self.onTextChange.bind(self)}
              />
              <button
                className="btn btn-primary"
                onClick={self.backClick.bind(self)}
              >
                Afbryd
              </button>
              <button
                className="btn btn-primary"
                onClick={self.sendClick.bind(self)}
              >
                Send
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { _apartments } = state.apartments;
  const { _rate } = state.rate;
  return { _apartments, _rate };
}

export default AuthenticatedComponent(
  withRouter(connect(mapStateToProps)(LetterEditor))
);
