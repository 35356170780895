import React from 'react';
import { connect } from 'react-redux';
import NewsService from '../services/NewsService'

class News extends React.Component {
  constructor(props) {
    super(props)
    NewsService.publicNewsGet();
  }

  render() {
    var newsEntries = null;
    if (this.props._publicNews) {
      newsEntries = this.props._publicNews.map(function (newsEntry) {

        var test = newsEntry.content;
        return (
          <div className="card card-default mb-1" key={newsEntry.id}>
            <div className="card-body" >
              <div dangerouslySetInnerHTML={{ __html: test }} ></div>
            </div>

          </div>
        );

      });
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-md color ">
            <h2 className="textAlignCenter my-4">Nyheder fra Vanløsegaard</h2>
            {newsEntries}
          </div>
        </div>
      </div>
    );
  }

}

function mapStateToProps(state) {
  const { _publicNews, _adminNews } = state.news
  return { _publicNews, _adminNews };
}

export default connect(mapStateToProps)(News)
