import request from 'reqwest';
import when from 'when';
import { HEATING_URL, HEATING_GET } from '../constants/HeatingConstants';
import AppDispatcher from '../dispatchers/AppDispatcher.js';
import { got } from '../actions/HeatingActions';
import { store } from '../index'


class HeatingService {

  get() {
    request({
      url: HEATING_URL,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState()._jwt
      }
    })
      .then(function (response) {
        got(response);
      });
  }
}

export default new HeatingService()
