import { APARTMENTTENANT_GET, APARTMENTTENANT_DELETE } from "../constants/ApartmentTenantConstants"


export function gotApartmentTenant(apartmentTenant, apartment) {
  return {
    type: APARTMENTTENANT_GET,
    apartmentTenant: apartmentTenant,
    apartment: apartment
  }
}

export function deleteApartmentTenant(apartmentTenant, apartment) {
  return {
    type: APARTMENTTENANT_DELETE,
    apartmentTenant: apartmentTenant,
    apartment: apartment
  }
}