import { APARTMENTS_GET, APARTMENT_CREATE, APARTMENT_CHANGE, APARTMENT_DELETE, APARTMENT_PUT_SALE, APARTMENT_CHECK_REPORT, APARTMENT_GET } from '../constants/ApartmentConstants';
import { LOGOUT_USER } from '../constants/LoginConstants';
import { APARTMENTTENANT_GET, APARTMENTTENANT_CHANGE, APARTMENTTENANT_DELETE, APARTMENTTENANT_CREATE } from '../constants/ApartmentTenantConstants';

export function apartments(state = { _apartments: [] }, action) {
  switch (action.type) {
    case APARTMENTS_GET:
      return { ...state, _apartments: action.apartments }
    case APARTMENT_GET:
      {
        let tempApartments = state._apartments.filter(x => x.id !== action.apartment.id);
        tempApartments.push(JSON.parse(JSON.stringify(action.apartment)));
        tempApartments.sort((a, b) => a.number - b.number);
        return { ...state, _apartments: tempApartments }
      }
    case APARTMENT_DELETE:
      {
        let tempApartments = state._apartments.filter(x => x.id !== action.apartment.id);
        return { ...state, _apartments: tempApartments }
      }
    case APARTMENT_CHECK_REPORT:
      {
        console.log(action.apartment)
        let tempApartments = state._apartments.filter(x => x.id !== action.apartment.id)
        tempApartments.push(action.apartment)
        tempApartments.sort((a, b) => a.number - b.number);
        return { ...state, _apartments: tempApartments };
      }
    case APARTMENTTENANT_GET:
      {
        let tempApartment = action.apartment;
        tempApartment.apartmentTenants = tempApartment.apartmentTenants.filter(x => x.id !== action.apartmentTenant.id);
        tempApartment.apartmentTenants.push(action.apartmentTenant);

        let tempApartments = state._apartments.filter(x => x.id !== tempApartment.id);
        tempApartments.push(JSON.parse(JSON.stringify(tempApartment)));
        tempApartments.sort((a, b) => a.number - b.number);
        return { ...state, _apartments: tempApartments }
      }
    case APARTMENTTENANT_DELETE:
      {
        let tempApartment = action.apartment;
        tempApartment.apartmentTenants = tempApartment.apartmentTenants.filter(x => x.id !== action.apartmentTenant.id);

        let tempApartments = state._apartments.filter(x => x.id !== tempApartment.id);
        tempApartments.push(JSON.parse(JSON.stringify(tempApartment)));
        tempApartments.sort((a, b) => a.number - b.number);
        return { ...state, _apartments: tempApartments }
      }
    case LOGOUT_USER:
      return { ...state, _apartments: [] }
    default:
      return state;
  };
}