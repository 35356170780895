import React from 'react';
import AuthenticatedComponent from '../AuthenticatedComponent';
import WaitingListService from '../../services/WaitingListService.js';
import { WAITINGLIST_REPORT_URL, WAITINGLIST_COUNT_URL } from '../../constants/WaitingListConstants.js';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WaitingListComponent from './subcomponents/WaitingListComponent';

class WaitingList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentInitiated: false
        }
    }

    componentDidMount() {
        if (this.props._applicationWaitingList.length === 0) {
            WaitingListService.getApplications();
        }
        WaitingListService.getWaitingList();
    }

    generateReport() {
        window.open(
            WAITINGLIST_REPORT_URL,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    intiatePayments(e) {
        e.preventDefault();
        WaitingListService.startPayment();
        this.props.history.push("/waitinglistLetter?type=regning");
    }

    stopPayments(e) {
        e.preventDefault();
        WaitingListService.countToBeDeleted().then((responseData) => {
            //this.setState({ dataSource: this.state.dataSource.cloneWithRows(responseData.movies), loaded: true, });
            var count = responseData;
            // console.log(responseData);
            var r = window.confirm('Advarsel! Du sletter i alt ' + count + ' person(er) fra ventelisten hvis du trykker OK');
            if (r == true) {
                WaitingListService.stopPayment().then((response) => {
                    window.alert('Betalingsstop planlagt, gennemføres om ' + response + ' minutter, kontakt administrator hvis det skal aflyses');
                });
            }
        });
    }

    sendFollowup(e) {
        e.preventDefault();
        this.props.history.push("/waitinglistLetter?type=rykker");
        // RouterContainer.get().transitionTo('/waitinglistLetter', null, { type: 'rykker' });
    }

    render() {
        var self = this;
        if (this.props._waitingList) {
            var list1 = [];
            var list2 = [];
            var list3 = [];
            this.props._waitingList.map(function (waitingListEntry) {
                if (waitingListEntry.initiated && waitingListEntry.listtype.letter!="B" && self.state.paymentInitiated == false ) {
                    self.setState({ paymentInitiated: true });
                }
                switch (waitingListEntry.listtype.id) {
                    case 1:
                        list1.push(<WaitingListComponent isWaitingList={true} waitingListEntry={waitingListEntry} count={list1.length + 1} ></WaitingListComponent>);
                        break;
                    case 2:
                        list2.push(<WaitingListComponent isWaitingList={true} waitingListEntry={waitingListEntry} count={list2.length + 1} ></WaitingListComponent>);
                        break;
                    case 3:
                        list3.push(<WaitingListComponent isWaitingList={true} waitingListEntry={waitingListEntry} count={list3.length + 1} ></WaitingListComponent>);
                        break;
                    default:
                        break;
                }
            });

        }
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <button className="btn btn-primary" onClick={self.generateReport.bind(this)}>Udskriv</button>
                            {this.state.paymentInitiated ?
                                <React.Fragment>
                                    <button className="btn btn-primary ml-1" onClick={self.stopPayments.bind(this)}>Stop betalingsperiode</button>
                                    <button className="btn btn-primary ml-1" onClick={self.sendFollowup.bind(this)}>Send rykker</button>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <button className="btn btn-primary ml-1" onClick={this.intiatePayments.bind(this)}>Send regninger (Start ny betalingsperiode)</button>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div id="waitingList">
                                <table className="table table-responsive table-striped table-hover">
                                    <thead className='textAlignLeft'>
                                        <tr>
                                            <th style={{ textAlign: "center", minWidth: "5rem" }}>#</th>
                                            {this.state.paymentInitiated && <th className='textAlignLeft'>Betalt</th>}
                                            <th className='textAlignLeft'>Navn</th>
                                            <th className='textAlignLeft'>Adresse</th>
                                            <th className='textAlignLeft'>Email</th>
                                            <th className='textAlignLeft'>Telefon</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list1}
                                        {list2}
                                        {list3}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

function mapStateToProps(state) {
    const { _waitingList, _applicationWaitingList } = state.waitingList
    return { _waitingList, _applicationWaitingList };
}

export default AuthenticatedComponent(withRouter(connect(mapStateToProps)(WaitingList)))