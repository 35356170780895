import AppDispatcher from '../dispatchers/AppDispatcher.js';
import { WAITINGLIST_UPDATE, WAITINGLIST_DELETE, WAITINGLIST_GET, WAITINGLIST_APPLICATION_UPDATE, WAITINGLIST_APPLICATION_DELETE, WAITINGLIST_APPLICATION_GET, WAITINGLIST_APPLICATION_APPROVE } from '../constants/WaitingListConstants.js';



export function updateWaitingListEntry(waitingListEntry) {
  return {
    type: WAITINGLIST_UPDATE,
    waitingListEntry: waitingListEntry
  }
}

export function deleteWaitingListEntry(waitingListEntry) {
  return {
    type: WAITINGLIST_DELETE,
    waitingListEntry: waitingListEntry
  }
}

export function gotWaitingList(waitingList) {
  return {
    type: WAITINGLIST_GET,
    waitingList: waitingList
  }
}

export function approveApplicationListEntry(waitingListEntry) {
  return {
    type: WAITINGLIST_APPLICATION_APPROVE,
    waitingListEntry: waitingListEntry
  }
}

export function updateApplicationListEntry(waitingListEntry) {
  return {
    type: WAITINGLIST_APPLICATION_UPDATE,
    waitingListEntry: waitingListEntry
  }
}

export function deleteApplicationListEntry(waitingListEntry) {
  return {
    type: WAITINGLIST_APPLICATION_DELETE,
    waitingListEntry: waitingListEntry
  }
}

export function gotApplicationList(applicationWaitingList) {
  return {
    type: WAITINGLIST_APPLICATION_GET,
    applicationWaitingList: applicationWaitingList
  }
}