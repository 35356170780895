import request from 'reqwest';
import when from 'when';
import { APARTMENTS_URL, APARTMENT_URL, APARTMENT_SALE } from '../constants/ApartmentConstants';
import AppDispatcher from '../dispatchers/AppDispatcher.js';
import AuthService from './AuthService'
import { gotApartments, createApartment, changeApartment, saleApartment, deleteApartment, gotApartment } from '../actions/ApartmentActions';
import { store } from '../index';
import { gotApartmentTenant, deleteApartmentTenant } from '../actions/ApartmentTenantActions';
import { APARTMENTTENANTS_URL } from '../constants/ApartmentTenantConstants';

class ApartmentTenantService {

  getApartmentTenant(id) {
    request({
      url: APARTMENTTENANTS_URL + "/" + id,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
      .then(function (response) {
        store.dispatch(gotApartmentTenant(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  createApartmentTenant(apartmentTenant, apartment) {
    return request({
      url: APARTMENTTENANTS_URL,
      method: 'post',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(apartmentTenant)
    })
      .then(function (response) {
        store.dispatch(gotApartmentTenant(response, apartment));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  updateApartmentTenant(apartmentTenant, apartment) {
    return request({
      url: APARTMENTTENANTS_URL + "/" + apartmentTenant.id,
      method: 'put',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(apartmentTenant)
    })
      .then(function (response) {
        store.dispatch(gotApartmentTenant(apartmentTenant, apartment));
      })
      .catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      })
  }

  deleteApartmentTenant(apartmentTenant, apartment) {
    return request({
      url: APARTMENTTENANTS_URL + "/" + apartmentTenant.id,
      method: 'delete',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(apartmentTenant)
    }).then(function (response) {
      store.dispatch(deleteApartmentTenant(response, apartment));
    }).catch(function (exception) {
      if (exception.status == 401) {
        AuthService.logout()
      }
    })
  }

}

export default new ApartmentTenantService()
