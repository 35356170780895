import { RATE_GET, RATE_UPDATE, BOARD_CREATE, BOARD_CHANGE, BOARD_DELETE, BOARD_GET, MEMBER_UPDATE, MEMBER_GET } from '../constants/RateConstants';
import { LOGOUT_USER } from '../constants/LoginConstants';


export function rate(state = { _rate: null, _board: null, _boardLoading: false, _chairman: null, _chairman_password: null, _treasurer: null, _treasurer_password: null, _secretary: null, _secretary_password: null, _vicechairman: null, _vicechairman_password: null, _boardmember1: null, _boardmember1_password: null, _boardmember2: null, _boardmember2_password: null, _boardmember3: null, _boardmember3_password: null, _boardmember4: null, _boardmember4_password: null }, action) {
  switch (action.type) {
    case RATE_GET:
      return { ...state, _rate: action.rate };
    case RATE_UPDATE:
      return { ...state, _rate: action.rate };
    case MEMBER_GET:
    case MEMBER_UPDATE:
      switch (action.member.type) {
        case 'chairman':
          return { ...state, _chairman: action.member.value, _chairman_password: action.member.pass };
        case 'vicechairman':
          return { ...state, _vicechairman: action.member.value, _vicechairman_password: action.member.pass };
        case 'treasurer':
          return { ...state, _treasurer: action.member.value, _treasurer_password: action.member.pass };
        case 'secretary':
          return { ...state, _secretary: action.member.value, _secretary_password: action.member.pass };
        case 'boardmember1':
          return { ...state, _boardmember1: action.member.value, _boardmember1_password: action.member.pass };
        case 'boardmember2':
          return { ...state, _boardmember2: action.member.value, _boardmember2_password: action.member.pass };
        case 'boardmember3':
          return { ...state, _boardmember3: action.member.value, _boardmember3_password: action.member.pass };
        case 'boardmember4':
          return { ...state, _boardmember4: action.member.value, _boardmember4_password: action.member.pass };
        default:
          return { ...state }
      }
    case BOARD_GET:
      return { ...state, _board: action.board }
    case BOARD_CHANGE:
      {
        console.log("action")
        console.log()

        let boardJson = JSON.stringify(state._board);
        let board = JSON.parse(boardJson);

        board.forEach(function (entry, index) {

          if (action.board == entry.id) {
            state._board[index] = action
          }
        })
        return { ...state, _boardLoading: false, _board: board }
      }
    case BOARD_DELETE:
      {
        let boardJson = JSON.stringify(state._board);
        let board = JSON.parse(boardJson);

        board.forEach(function (entry, index) {
          if (entry.id == action.board.id) {
            this._board.splice(index, 1)
          }
        })
        return { ...state, _board: board }
      }
    case BOARD_CREATE:
      {
        let boardJson = JSON.stringify(state._board);
        let board = JSON.parse(boardJson);

        board.forEach(function (entry, index) {
          if (entry.new) {
            this._board.splice(index, 1)
          }
        })

        if (board) {
          board.push(action.board)
        }
        return { ...state, _boardLoading: false, _board: board }
      }
    case LOGOUT_USER:
      return {...state, _rate: null, _board: []}
    default:
      return state;
  };
}