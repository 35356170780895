import {BASE_URL} from '../constants/SystemConstants';

export const WAITINGLIST_UPDATE= 'WAITINGLIST_APPLY';
export const WAITINGLIST_DELETE= 'WAITINGLIST_DELETE';
export const WAITINGLIST_GET= 'WAITINGLIST_GET';
export const WAITINGLIST_APPLICATION_UPDATE= 'WAITINGLIST_APPLICATION_UPDATE';
export const WAITINGLIST_APPLICATION_DELETE= 'WAITINGLIST_APPLICATION_DELETE';
export const WAITINGLIST_APPLICATION_GET= 'WAITINGLIST_APPLICATION_GET';
export const WAITINGLIST_APPLICATION_APPROVE= 'WAITINGLIST_APPLICATION_APPROVE';

export const WAITINGLIST_URL= BASE_URL + 'waitinglist';
export const WAITINGLIST_REPORT_URL = BASE_URL + 'waitinglist/report';
export const WAITINGLIST_UPDATE_PAID_URL = BASE_URL + 'waitinglist/paid';
export const WAITINGLIST_STOP_PAYMENTS_URL = BASE_URL + 'waitinglist/stopPayments';
export const WAITINGLIST_START_PAYMENTS_URL = BASE_URL + 'waitinglist/startPayments';
export const WAITINGLIST_COUNT_URL= BASE_URL + 'waitinglist/deleted';
export const WAITINGLIST_APPLICATIONS_URL = BASE_URL + 'waitinglist/applications';
export const WAITINGLIST_APPLICATIONS_APPROVED_URL= BASE_URL + 'waitinglist/applications/approve';

export const WAITINGLIST_SCHEDULE_CLEANUP_URL= BASE_URL + 'waitinglist/schedulecleanup';