import React from 'react';
import AuthenticatedComponent from './AuthenticatedComponent';
import EmailService from '../services/EmailService.js';
import WaitingListService from '../services/WaitingListService.js';
import ReactQuill from 'react-quill';
import { withRouter } from 'react-router-dom';

class WaitingListLetterEditorComponent extends React.Component {
  constructor(props) {
    super(props);
    var rykker = false;
    if (this.props.query.get("type")) {
      rykker = this.props.query.get("type") === 'rykker';
    }
    var letter = ""
    letter = this.getLetterState(rykker)
    this.state = {
      rykker: rykker,
      letter: letter
    }
  }

  backClick = () => {
    this.props.history.push('/venteliste');
  }

  sendClick = (e) => {
    e.preventDefault();
    if (this.state.rykker) {
      EmailService.sendWaitingListPaymentLateNotice({
        subject: 'RYKKER: Udeblevet betaling af venteliste gebyr',
        content: this.state.letter
      });
      this.props.history.push('/venteliste');
    }
    else {
      WaitingListService.startPayment();
      WaitingListService.getWaitingList();
      EmailService.sendWaitingListPaymentNotice({
        subject: 'Opkrævning af venteliste gebyr',
        content: this.state.letter
      });
      this.props.history.push('/venteliste');
    }
  }

  getLetterState = (rykker) => {
    var date = new Date();
    var currentDanishDate = date.toLocaleString('da-DK', { weekday: 'long', year: 'numeric', month: '2-digit', day: 'numeric' });
    currentDanishDate = currentDanishDate.charAt(0).toUpperCase() + currentDanishDate.slice(1)
    if (rykker)
      return "<div style=\"font-family: sans-serif;\"><div><span style=\"font-size: 18px;\"><strong>Andelsboligforeningen Vanl&oslash;segaard</strong></span></div><div>&nbsp;</div><div><strong>Vedr.: Udeblevet betaling af venteliste gebyr</strong></div><div>&nbsp;</div><div><div>Du bedes venligst betale ved at trykke p&aring; nedenst&aring;ende betalingslink.</div><div>&nbsp;</div><div>LINK</div><div>&nbsp;</div><div>Foretr&aelig;kkes bankoverf&oslash;rsel bedes du indbetale nedenst&aring;ende bel&oslash;b til Vanl&oslash;segaards konto inden den " + currentDanishDate + ":</div></div><div>&nbsp;</div><div><em><strong>NB!</strong> Hvis du betaler vha. bankoverførsel er det vigtigt at du fremsender billedkvittering til kontoret@vg.dk.</em></div><div><em><strong>NB!</strong> Betaler du ikke bliver du fjernet fra ventelisten.</em></div><div>&nbsp;</div><div>Venteliste gebyr: DKK 200</div><div>&nbsp;</div><div>Rykkergebyr: DKK 50</div><div>&nbsp;</div><div>I alt at betale: DKK 250</div><div>&nbsp;</div><div>Betalingsdato: " + currentDanishDate + "</div><div>&nbsp;</div><div>Konto nr.: 11280277</div><div>&nbsp;</div><div>Reg nr.: 4183</div><div>&nbsp;</div><div><strong><em>Hvis denne rykker ikke betales vil du blive fjernet fra ventelisten.</em></strong></div><div>&nbsp;</div><div>&nbsp;</div><div>Med venlig hilsen</div><div>Bestyrelsen</div><div>A/B Vanl&oslash;segaard</div><div>&nbsp;</div><div>-------------------------------------</div><div>Jernbane All&eacute; 92, st.th. 2720 Vanl&oslash;se</div><div>www.vanlosegaard.dk</div><div>Telefon 38 74 48 52</div><div>Cvr nr. 43076019</div><div>Matr. nr. 2495</div><div>BBR nr. 285529</div></div>";
    //return "<div style=\"font-family: sans-serif;\"><div><span style=\"font-size: 18px;\"><b>Andelsboligforeningen Vanl&oslash;segaard</b></span></div><div><br /></div><div><span><b>Vedr.: Betaling af venteliste gebyr</b></span></div><div><br /></div><div>Du bedes venligst indbetale nedenst&aring;ende bel&oslash;b til  Vanl&oslash;segaards konto inden den :</div><div><br /></div><div><span><i><b>NB!</b> Betaler du ikke bliver du fjernet fra ventelisten.</i></span></div><div><br /></div><div>At betale: DKK XXXX </div><div><br /><div>Betalingsdato:  " + currentDanishDate + "</div><div><br /><div>Konto nr.:  XXXXXXXXXXXXXX</div><div><br /><div>Reg nr.:  XXXX</div><div><br /></div><div>Betales bel&oslash;bet ikke inden den angivne dato vil du modtage en rykker hvor der till&aelig;gges DKK XX  som rykkergebyr.</div><div><br /></div><div><br /></div><div><span>Med venlig hilsen</span></div><div>Bestyrelsen</div><div>A/B Vanl&oslash;segaard</div><div><br /></div><div>-------------------------------------</div><div><span>Jernbane All&eacute; 92, st.th. 2720 Vanl&oslash;se</span></div><div>www.vanlosegaard.dk</div><div>Telefon 38 74 48 52</div><div>Cvr nr. 43076019</div><div>Matr. nr. 2495</div><div>BBR nr. 285529</div></div>";
    return "<div style=\"font-family: sans-serif;\"><div><span style=\"font-size: 18px;\"><strong>Andelsboligforeningen Vanl&oslash;segaard</strong></span></div><div>&nbsp;</div><div><strong>Vedr.: Betaling af venteliste gebyr</strong></div><div>&nbsp;</div><div>Du bedes venligst betale ved at trykke p&aring; nedenst&aring;ende betalingslink.</div><div>&nbsp;</div><div>LINK</div><div>&nbsp;</div><div>Foretr&aelig;kkes bankoverf&oslash;rsel bedes du indbetale nedenst&aring;ende bel&oslash;b til Vanl&oslash;segaards konto inden den " + currentDanishDate + ":</div><div>&nbsp;</div><div><em><strong>NB!</strong> Hvis du betaler vha. bankoverførsel er det vigtigt at du fremsender billedkvittering til kontoret@vg.dk.</em></div><div><em><strong>NB!</strong> Betaler du ikke bliver du fjernet fra ventelisten.</em></div><div>&nbsp;</div><div>At betale: DKK 200</div><div>&nbsp;</div><div>Betalingsdato: " + currentDanishDate + "</div><div>&nbsp;</div><div>Konto nr.: 11280277</div><div>&nbsp;</div><div>Reg nr.: 4183</div><div>&nbsp;</div><div>Betales bel&oslash;bet ikke inden den angivne dato vil du modtage en rykker hvor der till&aelig;gges DKK 50 som rykkergebyr.</div><div>&nbsp;</div><div>&nbsp;</div><div>Med venlig hilsen</div><div>Bestyrelsen</div><div>A/B Vanl&oslash;segaard</div><div>&nbsp;</div><div>-------------------------------------</div><div>Jernbane All&eacute; 92, st.th. 2720 Vanl&oslash;se</div><div>www.vanlosegaard.dk</div><div>Telefon 38 74 48 52</div><div>Cvr nr. 43076019</div><div>Matr. nr. 2495</div><div>BBR nr. 285529</div></div>";
  }


  onTextChange = (value) => {
    this.setState({ letter: value });
  }

  render = () => {
    var self = this;
    return (
      <div>
        {this.state.loading && <div>Loading...</div>}
        {!this.state.loading &&
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div id="emailEdit">
                      <ReactQuill theme="snow" value={this.state.letter} onChange={self.onTextChange.bind(self)} />
                      <button className="btn btn-primary" onClick={self.backClick.bind(self)}>Afbryd</button>
                      <button className="btn btn-primary" onClick={self.sendClick.bind(self)}>Send</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
export default AuthenticatedComponent(withRouter(WaitingListLetterEditorComponent));