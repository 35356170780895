import React from 'react';

import AuthenticatedComponent from '../AuthenticatedComponent';
import ApartmentService from '../../services/ApartmentService.js';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import "react-datepicker/dist/react-datepicker.css";
import CreateApartmentComponent from './subcomponents/CreateApartmentComponent';
import ApartmentTableRowComponent from './subcomponents/ApartmentTableRowComponent';



class Apartments extends React.Component {
    constructor(props) {
        super(props);
        this.state = { report: [] };
    }

    addToReportList = (id) => {
        let report = this.state.report;
        report.push(id);
        this.setState({report: report})
    }

    removeFromReportList = (id) => {
        let report = this.state.report.filter(x => x !== id);
        this.setState({report: report})
    }

    newApartment(e) {
        e.preventDefault();
        this.setState({ createNew: true });
    }

    cancelNewApartment(e) {
        e.preventDefault();
        this.setState({ createNew: false });
    }

    componentDidMount() {
        this.requestApartments();
    }

    requestApartments() {
        if (this.props._apartments.length === 0) {
            ApartmentService.getApartments();
        }
    }

    reportClick() {
        var self = this;
        var checked = []
        this.props.history.push("/letter?apartments=" + this.state.report.join(","));
        // RouterContainer.get().transitionTo('/letter', null, { apartments: checked.join(".") });

    }

    render() {
        let self = this;
        if (this.props._apartments) {
            var existingApartments = this.props._apartments.map(function (apartment) {
                return <ApartmentTableRowComponent key={apartment.id} apartment={apartment} report={self.state.report} addToReportList={self.addToReportList} removeFromReportList={self.removeFromReportList}></ApartmentTableRowComponent>;
            });
        }

        var createApartmentElement = <CreateApartmentComponent></CreateApartmentComponent>;
        // var createApartmentElement = <React.Fragment></React.Fragment>;
        return (

            <div className="container standardBackground">
                <div className="row">
                    {createApartmentElement}
                </div>
                <div className="row">
                    <div className="col-md">
                        <table id="apartmentsTable" className="table table-responsive"><thead>
                            <tr>

                                <th></th>
                                <th></th>
                                <th></th>
                                <th><button className="btn btn-primary" onClick={this.reportClick.bind(this)}>Generer Email</button></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th style={{ textAlign: "center" }}>Andels nr.</th>
                                <th className='textAlignLeft'>Adresse</th>
                                <th className='textAlignLeft'>Beboere</th>
                                <th style={{ textAlign: "center" }}>Indkald Venteliste</th>
                                <th style={{ textAlign: "center" }}>Sat til salg</th>
                            </tr>
                        </thead>
                            <tbody>
                                {existingApartments}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { _apartments } = state.apartments;
    return { _apartments };
}



export default AuthenticatedComponent(withRouter(connect(mapStateToProps)(Apartments)))