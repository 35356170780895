import React from 'react';
import Auth from '../services/AuthService'
import RateService from '../services/RateService.js';
import { Route, RouteHandler, Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import NewsService from '../services/NewsService';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthenticatedComponent from './AuthenticatedComponent';

class Home extends React.Component {
  componentDidMount() {
    RateService.ratesGet();
    NewsService.publicNewsGet();
    NewsService.adminNewsGet();
  }

  getRateState() {
    return this.props._rate;
  }

  getNewsState() {

    return this.props._publicNews;

  }

  getAdminNewsState() {

    return this.props._adminNews;

  }

  _onChange() {
    this.setState({
      rate: this.getRateState(),
      news: this.getNewsState(),
      adminNews: this.getAdminNewsState()
    });
  }

  deletePublic(e) {
    e.preventDefault();
    var id = this.state.news.id;
    console.log(id);
    NewsService.publicNewsDelete({
      id: id
    }).catch(function (err) {
      alert('Der opstod en fejl');
      console.log("error deleting", err);
    })
    this.requestPublicNews();
    this.setState({
      news: this.getNewsState()
    });
    return;
  }

  savePublicEdit(e) {
    e.preventDefault();
    var id = this.state.news.id;
    var content = this.state.content;
    NewsService.publicNewsPut({
      id: id,
      content: content
    });
    this.requestPublicNews();
    this.setState({
      news: this.getNewsState()
    });
    return;
  }

  deleteAdmin(e) {
    e.preventDefault();
    var id = this.state.news.id;
    console.log(id);
    NewsService.adminNewsDelete({
      id: id
    }).catch(function (err) {
      alert('Der opstod en fejl');
      console.log("error deleting", err);
    })
    this.requestAdminNews();
    this.setState({
      adminNews: this.getAdminNewsState()
    });
    return;
  }

  saveAdminEdit(e) {
    e.preventDefault();
    var id = this.state.news.id;
    var content = this.state.content;
    NewsService.adminNewsPut({
      id: id,
      content: content
    });
    this.requestAdminNews();
    this.setState({
      adminNews: this.getAdminNewsState()
    });
    return;
  }


  render() {
    var self = this
    var createReactClass = require('create-react-class');
    var NewsEntryClass = createReactClass({
      getInitialState: function () {
        return {
          editNews: false,
          content: this.props.news.content,
          news: this.props.news
        };
      },

      onClick: function () {
        console.log(this.state.editNews);
        if (this.state.editNews) {
          this.setState({ editNews: false });
        }
        else {
          this.setState({ editNews: true });
        }
      },

      getNewsState: function () {
        return this.props._publicNews;
      },

      requestPublicNews: function () {
        NewsService.publicNewsGet();
      },

      onTextChange(value) {
        this.setState({ content: value });
      },

      render: function () {
        return (
          <React.Fragment>
            <button className="floatRight btn btn-xs textAlignCenter" onClick={self.deletePublic.bind(this)}><span className='bi bi-circle-fill'></span></button>
            <button className="floatRight btn btn-xs textAlignCenter" onClick={this.onClick}><span className='bi bi-document-text'></span></button>
            <div className="card card-default" style={{ marginBottom: "4em" }}>
              <div>
                {this.state.editNews ?
                  <React.Fragment>
                    <div className="card-body">
                      <ReactQuill theme="snow" value={this.state.content} onChange={this.onTextChange.bind(this)} />
                    </div>
                    <button className="floatRight btn btn-success btn-sm textAlignCenter" onClick={self.savePublicEdit.bind(this)} > Gem ændringer</button>
                  </React.Fragment>
                  :
                  <div className="card-body">
                    <div dangerouslySetInnerHTML={{ __html: this.state.content }}></div>
                  </div>
                }
              </div>
            </div>
          </React.Fragment>


        );
      }

    });

    var AdminNewsEntryClass = createReactClass({
      getInitialState: function () {
        console.log(this.props)
        return {
          editNews: false,
          content: this.props.news.content,
          news: this.props.news
        };
      },

      onClick: function () {
        console.log(this.state.editNews);
        if (this.state.editNews) {
          this.setState({ editNews: false });
        }
        else {
          this.setState({ editNews: true });
        }


      },


      getAdminNewsState: function () {

        return this.props._adminNews;

      },

      requestAdminNews: function () {

        NewsService.adminNewsGet();
      },

      onTextChange(value) {
        this.setState({ content: value });

      },



      render: function () {
        return (
          <React.Fragment>
            <button className="floatRight btn btn-xs textAlignCenter" onClick={self.deleteAdmin.bind(this)}><span className='bi bi-circle-fill'></span></button>
            <button className="floatRight btn btn-xs textAlignCenter" onClick={this.onClick}><span className='bi bi-document-text'></span></button>
            <div className="card card-default" style={{ marginBottom: "3em" }}>
              <React.Fragment>
                {this.state.editNews ?
                  <div>
                    <div className="card-body">
                      <ReactQuill theme="snow" value={this.state.content} onChange={this.onTextChange.bind(this)} />
                    </div>
                    <button className="floatRight btn btn-success btn-sm textAlignCenter" onClick={self.saveAdminEdit.bind(this)} > Gem ændringer</button>
                  </div>
                  :
                  <div className="card-body">
                    <div dangerouslySetInnerHTML={{ __html: this.state.content }}></div>
                  </div>
                }
              </React.Fragment>
            </div>
          </React.Fragment>
        );
      }

    });
    var newsEntries = null;
    if (this.props._publicNews) {
      console.log(this.props._publicNews);
      newsEntries = this.props._publicNews.map(function (newsEntry) {

        var test = newsEntry.content;
        // var newsElement = React.createElement(newsEntryClass, { 'news': newsEntry });
        return (
          <NewsEntryClass news={newsEntry} key={newsEntry.id} />
        );

      });
    }

    var adminNewsEntriers = null;
    if (this.props._adminNews) {
      adminNewsEntriers = this.props._adminNews.map(function (newsEntry) {

        var test = newsEntry.content;
        // var adminNewsElement = React.createElement(adminNewsEntryClass, { 'news': newsEntry });
        return (
          <AdminNewsEntryClass news={newsEntry} key={newsEntry.id}/>
        );

      });
    }
    return (
      <div>
        <div className="container">
          <div className="row">
              <div className="standardBackground col-md-12">
                <h2 className="textAlignCenter">Velkommen til Vanløsegaard!</h2>
                {this.props._rate && <div className="textAlignCenter">
                  Andelskronen er
                <Link className="Navigation-link" to="indstillinger">{this.props._rate} kr.</Link>
                </div>}
                <h2 className="textAlignCenter">Opslag</h2>
                {adminNewsEntriers}
                <h2 className="textAlignCenter">Offentlige nyheder</h2>
                {newsEntries}
              </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { _publicNews, _adminNews } = state.news;
  const { _rate } = state.rate;
  return { _publicNews, _adminNews, _rate };
}

export default withRouter(AuthenticatedComponent(connect(mapStateToProps)(Home)))