import React from 'react';
import WaitingListService from '../../services/WaitingListService.js';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

class WaitingListApply extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            waitingListApplication: {
                entry_date: new Date(),
                waiting_list_type_id: 3,
                note: '',
                person: {
                    firstname: '',
                    lastname: '',
                    address: '',
                    postcode: '',
                    suburb: '',
                    phone: '',
                    work: '',
                    email: ''
                }
            },
            referer: {
                firstname: '',
                lastname: '',
                address: '',
                postcode: '',
                suburb: '',
                phone: '',
                work: '',
                email: ''
            },
            liveHere: false,
            haveReference: false,
            saved: false,
            error: null
        }
    }

    applyClick(e) {
        e.preventDefault();

        if (!this.state.waitingListApplication.person.firstname || !this.state.waitingListApplication.person.lastname) {
            this.setState({
                saving: false,
                error: "Fornavn og efternavn er obligatoriske"
            });
        }
        else if (!this.state.waitingListApplication.person.address || !this.state.waitingListApplication.person.suburb || !this.state.waitingListApplication.person.postcode) {
            this.setState({
                saving: false,
                error: "Adresse felterne er obligatoriske"
            });
        }
        else if (!this.state.waitingListApplication.person.email || !this.state.waitingListApplication.person.phone) {
            this.setState({
                saving: false,
                error: "Email og telefon felterne er obligatoriske"
            });
        }
        else if (this.state.haveReference && (!this.state.referer.firstname || !this.state.referer.lastname)) {
            this.setState({
                saving: false,
                error: "Reference fornavn og efternavn er obligatoriske"
            });
        }
        else if (this.state.haveReference && (!this.state.referer.address || !this.state.referer.postcode || !this.state.referer.suburb)) {
            this.setState({
                saving: false,
                error: "Reference adresse felterne er obligatoriske"
            });
        }
        else if (this.state.haveReference && (!this.state.referer.email || !this.state.referer.phone)) {
            this.setState({
                saving: false,
                error: "Reference e-mail og telefon felterne er obligatoriske"
            });
        }
        else {
            let requestData;
            if (this.state.liveHere) {
                this.setState({ waitingListApplication: { ...this.state.waitingListApplication, waiting_list_type_id: 1 } })
                requestData = {
                    waitingList: { ...this.state.waitingListApplication, waiting_list_type_id: 1 }
                };
            }

            if (this.state.haveReference) {
                this.setState({ waitingListApplication: { ...this.state.waitingListApplication, waiting_list_type_id: 2 } })
                requestData = {
                    waitingList: { ...this.state.waitingListApplication, waiting_list_type_id: 2 },
                    referer: this.state.referer
                };
            }

            if (!this.state.liveHere && !this.state.haveReference) {
                this.setState({ waitingListApplication: { ...this.state.waitingListApplication, waiting_list_type_id: 3 } })
                requestData = {
                    waitingList: { ...this.state.waitingListApplication, waiting_list_type_id: 3 }
                };
            }

            let self = this;

            var res = WaitingListService.createApplication(requestData)
                .then((response) => {
                    // TODO: server will start sending back payment link and will then require this app to send customers to payment gateway, if succesfull redirects to successpage if not redirect to failure page.

                    console.log(response);
                    
                    window.location.href = response; 

                    // self.setState({
                    //     saved: true
                    // });
                }).catch(function (err) {
                    alert("Der opstod en fejl");
                    console.log("Error saving", err);
                    self.setState({ error: err });
                });
        }
    }
    
    render() {
        var self = this;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 login center-block">
                        <div className="standardBackground">
                            <div id="waitingListApply">
                                <h2>Ansøg om venteliste</h2>
                                <p>Obs, læs venligst reglerne for ventelisterne inden du ansøger. <a href="https://www.vg.dk/lejligheder/#venteliste-section">Se reglerne her.</a></p>
                                {this.state.saved && <div>Ansøgning sendt.</div>}
                                {this.state.error && <div className="error">{this.state.error}</div>}
                                {!this.state.saved &&
                                    <form>
                                        <div className="form-group">
                                            <label  >Fornavn</label>
                                            <input type="text" value={this.state.waitingListApplication.person.firstname} onChange={(event) => this.setState({ waitingListApplication: { ...this.state.waitingListApplication, person: { ...this.state.waitingListApplication.person, firstname: event.target.value } } })} className=" form-control" id="firstname" required />
                                        </div>
                                        <div className="form-group">
                                            <label  >Efternavn</label>
                                            <input type="text" value={this.state.waitingListApplication.person.lastname} onChange={(event) => this.setState({ waitingListApplication: { ...this.state.waitingListApplication, person: { ...this.state.waitingListApplication.person, lastname: event.target.value } } })} className=" form-control" id="lastname" required />
                                        </div>
                                        <div className="form-group">
                                            <label  >Adresse</label>
                                            <input type="text" value={this.state.waitingListApplication.person.address} onChange={(event) => this.setState({ waitingListApplication: { ...this.state.waitingListApplication, person: { ...this.state.waitingListApplication.person, address: event.target.value } } })} className=" form-control" id="address" required />
                                        </div>
                                        <div className="form-group">
                                            <label  >By</label>
                                            <input type="text" value={this.state.waitingListApplication.person.suburb} onChange={(event) => this.setState({ waitingListApplication: { ...this.state.waitingListApplication, person: { ...this.state.waitingListApplication.person, suburb: event.target.value } } })} className=" form-control" id="suburb" required />
                                        </div>
                                        <div className="form-group">
                                            <label  >Postnummer</label>
                                            <input type="text" value={this.state.waitingListApplication.person.postcode} onChange={(event) => this.setState({ waitingListApplication: { ...this.state.waitingListApplication, person: { ...this.state.waitingListApplication.person, postcode: event.target.value } } })} className="form-control" id="postcode" required />
                                        </div>

                                        <div className="form-group">
                                            <label  >E-mail</label>
                                            <input type="text" value={this.state.waitingListApplication.person.email} onChange={(event) => this.setState({ waitingListApplication: { ...this.state.waitingListApplication, person: { ...this.state.waitingListApplication.person, email: event.target.value } } })} className=" form-control" id="email" required />
                                        </div>

                                        <div className="form-group">
                                            <label >Telefon</label>
                                            <input type="text" value={this.state.waitingListApplication.person.phone} onChange={(event) => this.setState({ waitingListApplication: { ...this.state.waitingListApplication, person: { ...this.state.waitingListApplication.person, phone: event.target.value } } })} className=" form-control" id="phone" required />
                                        </div>

                                        <div className="form-group">
                                            <div>
                                                <label>Noter</label>
                                            </div>
                                            <textarea className="form-control" rows="2" id="comment" value={this.state.waitingListApplication.note} onChange={(event) => this.setState({ waitingListApplication: { ...this.state.waitingListApplication, note: event.target.value } })} ></textarea>
                                        </div>

                                        <div className="form-group">
                                            <label className="col-md-12 marginLeft1" >Er du nuværende beboer?</label>
                                            <input className="col-md-12" type="checkbox" value={this.state.liveHere} onChange={(event) => this.setState({ liveHere: event.target.checked })} />
                                        </div>

                                        {!this.state.liveHere &&
                                            <div>
                                                <div className="form-group">
                                                    <label className="col-md-12 marginLeft1" >Er du blevet henvist af en nuværende beboer?</label>
                                                    <input className="col-md-12" type="checkbox" value={this.state.haveReference} onChange={(event) => this.setState({ haveReference: event.target.checked })} />
                                                </div>
                                                {this.state.haveReference &&
                                                    <div>
                                                        <div>
                                                        En andel kan kun have én skrevet op på liste D ad gangen. Hvis man som andelshaver skriver flere op, vil de blive slettet fra listen (D) og deres indbetaling mistes, da vi ikke automatisk kan overføre til liste E.
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="address">Intern Ref. Fornavn</label>
                                                            <input type="text" value={this.state.referer.firstname} onChange={(event) => this.setState({ referer: { ...this.state.referer, firstname: event.target.value } })} className=" form-control" id="referer_firstname" required />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="address">Intern Ref. Efternavn</label>
                                                            <input type="text" value={this.state.referer.lastname} onChange={(event) => this.setState({ referer: { ...this.state.referer, lastname: event.target.value } })} className=" form-control" id="referer_lastname" required />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="address">Adresse</label>
                                                            <input type="text" value={this.state.referer.address} onChange={(event) => this.setState({ referer: { ...this.state.referer, address: event.target.value } })} className=" form-control" id="referer_lastname" required />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="address">Postnummer</label>
                                                            <input type="text" value={this.state.referer.postcode} onChange={(event) => this.setState({ referer: { ...this.state.referer, postcode: event.target.value } })} className=" form-control" id="referer_lastname" required />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="address">By</label>
                                                            <input type="text" value={this.state.referer.suburb} onChange={(event) => this.setState({ referer: { ...this.state.referer, suburb: event.target.value } })} className=" form-control" id="referer_lastname" required />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="address">Ref. Telefon nr.</label>
                                                            <input type="text" value={this.state.referer.phone} onChange={(event) => this.setState({ referer: { ...this.state.referer, phone: event.target.value } })} className=" form-control" id="referer_lastname" required />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="address">Ref. E-mail</label>
                                                            <input type="text" value={this.state.referer.email} onChange={(event) => this.setState({ referer: { ...this.state.referer, email: event.target.value } })} className=" form-control" id="referer_lastname" required />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        <div className="form-buttons">
                                            <button type="submit" className="btn btn-primary" onClick={self.applyClick.bind(this)}>Indsend</button>
                                        </div>
                                    </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { _apartments, _apartment } = state.apartments
    return { _apartments, _apartment };
}

export default withRouter(connect(mapStateToProps)(WaitingListApply))
