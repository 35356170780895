import request from 'reqwest';
import { HANDYMAN_URL, HANDYMAN_PRIVATE_URL, HANDYMAN_PUBLIC_URL } from '../constants/HandyManConstants';
import AuthService from '../services/AuthService'
import { handymanPublicPut, handymanDelete, handymanPrivateGet, handymanPublicGet, addHandyManPrivate, addHandyManPublic } from '../actions/HandyManActions';
import { store } from '../index';


class HandyManService {
  create(handyMan) {
    return request({
      url: HANDYMAN_URL,
      method: 'post',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(handyMan)
    })
      .then(function (response) {
        if (response.type === "private") {
          store.dispatch(addHandyManPrivate(response));
        } else {
          store.dispatch(addHandyManPublic(response));
        }
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  update(handyMan) {
    return request({
      url: HANDYMAN_PUBLIC_URL + "/" + handyMan.id,
      method: 'put',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(handyMan)
    })
      .then(function (response) {
        store.dispatch(handymanPublicPut(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  delete(handyMan) {
    return request({
      url: HANDYMAN_URL + "/" + handyMan.id,
      method: 'DELETE',
      type: 'json',
      contentType: 'application/json',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      },
      data: JSON.stringify(handyMan)
    })
      .then(function () {
        store.dispatch(handymanDelete(handyMan));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  getPrivate() {
    request({
      url: HANDYMAN_PRIVATE_URL,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Authorization': 'Bearer ' + store.getState().login._jwt
      }
    })
      .then(function (response) {
        store.dispatch(handymanPrivateGet(response));
      }).catch(function (exception) {
        if (exception.status == 401) {
          AuthService.logout()
        }
      });
  }

  getPublic() {
    request({
      url: HANDYMAN_PUBLIC_URL,
      method: 'GET',
      crossOrigin: true
    })
      .then(function (response) {
        store.dispatch(handymanPublicGet(response));
      });
  }
}

export default new HandyManService()
