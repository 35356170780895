import {BASE_URL} from '../constants/SystemConstants';

export const RATE_URL= BASE_URL + 'settings/rate';
export const RATE_UPDATE= 'RATE_UPDATE';
export const RATE_GET= 'RATE_UPDATE';
export const BOARD_CREATE= 'BOARD_CREATE';
export const BOARD_CHANGE= 'BOARD_CHANGE';
export const BOARD_DELETE= 'BOARD_DELETE';
export const BOARD_GET= 'BOARD_GET';
export const BOARD_URL= BASE_URL + "settings/board/members";
export const SETTINGS_URL= BASE_URL + 'settings';
export const MEMBER_URL= BASE_URL + 'settings/board';
export const MEMBER_UPDATE= 'MEMBER_UPDATE';
export const MEMBER_GET= 'MEMBER_GET';